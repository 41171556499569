import {
    MDBContainer
} from "mdb-react-ui-kit";
export default function Unauthorized() {
    return (
        <MDBContainer className="text-center">
            <h1 className="mt-5">
                You're not authorized to access this page!
            </h1>
        </MDBContainer>
    )
}