import React from "react";
import {
  MDBContainer,
  MDBTypography,
  MDBCol,
  MDBRow,
  MDBBtn 
} from "mdb-react-ui-kit";

import banner from "../../assets/images/banner.jfif";
import graph_1 from "../../assets/images/graph_1.png";
import app from "../../assets/images/app.png";

import "./Storyboard.css";

export default function Storyboard() {

  return (
    
    <MDBContainer>
    <MDBRow>
        <MDBCol className="mt-1 mb-1" xl="12">
          <MDBRow className="mt-1 mb-1 mx-1">
              <div
                className="bg-image p-4 text-center shadow-1-strong rounded mb-2 text-white"
                style={{
                  backgroundImage: "url("+banner +")"
                }}
              >
              <MDBRow className="mt-1 mb-1 mx-5">
                <MDBCol className="mt-1 mb-1 container-size h-100" md="6">
                    <h1 className="mb-3 h1">3M™ Scotch-Weld™ Digital Dispensing Assistant</h1>

                    <h6 className="mb-2 h6">
                    Experience shorter machine setup time with our free new automation tool for Time-Pressure Systems – just enter the current conditions and desired flow rate.
                    </h6>
                    <div className="d-grid gap-4 mx-auto">
                      <MDBBtn           
                        className="me-2 my-3"
                        color="light"
                        size="lg"
                        href="https://dda.3m.com/"
                        rel="noreferrer"
                        target="_blank">TRY THE DIGITAL DISPENSING ASSISTANT FOR YOUR TIME-PRESSURE SYSTEM NOW</MDBBtn>
                    </div>
                  </MDBCol>
                  <MDBCol md="6"></MDBCol>
              </MDBRow>
              </div>
          </MDBRow>

                <MDBRow className="mt-1 mb-1">
                  <MDBCol className="mt-1 mb-1" md="4">
                    <MDBContainer  className="square rounded-6 border border-dark px-3 py-2 bg-white " style={{height: "100%"}}>
                      <MDBTypography className="title-box">
                          Benefits of time-pressure dispenser:
                      </MDBTypography>
                      <ul className="list-unstyled">
                          <li className="mb-1"><i className="fas fa-angle-right"></i> Low initial cost</li>
                          <li className="mb-1"><i className="fas fa-angle-right"></i> Low mechanical maintenance</li>
                          <li className="mb-1"><i className="fas fa-angle-right"></i> No metering required (when using 2-part duo-paks)</li>
                          <li className="mb-0"><i className="fas fa-angle-right"></i> Simple operation</li>
                      </ul>
                    </MDBContainer>
                  </MDBCol>
                  <MDBCol className="mt-1 mb-1 mh-100" md="4">
                    <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white " style={{height: "100%"}}>
                        <MDBTypography className="title-box">
                          Flow rate control challenging due to:
                        </MDBTypography>
                        <ul className="list-unstyled">
                            <li className="mt-2 mb-2 "><i className="fas fa-angle-right"></i> Temperature</li>
                            <li className="mb-2"><i className="fas fa-angle-right"></i> Viscosity</li>
                            <li className="mb-2"><i className="fas fa-angle-right"></i> Age of material</li>
                        </ul>
                    </MDBContainer>
                  </MDBCol>
                  <MDBCol className="mt-1 mb-1 mh-100" md="4">
                      <MDBContainer  className="square rounded-6 border border-dark px-3 py-2 bg-white" style={{height: "100%"}}>
                          <MDBTypography className="title-box">
                                  Uncontrolled process can cause:
                              </MDBTypography>
                              <ul className="list-unstyled">
                                  <li className="mt-2 mb-2"><i className="fas fa-angle-right"></i> Uncontrolled adhesive flow rates</li>
                                  <li className="mb-2"><i className="fas fa-angle-right"></i> Wasted time recalibrating</li>
                                  <li className="mb-2"><i className="fas fa-angle-right"></i> Scrap and Rework</li>
                                  <li className="mb-2"><i className="fas fa-angle-right"></i> Wasted adhesive </li>
                              </ul>
                      </MDBContainer>
                  </MDBCol>
                </MDBRow>

          <MDBRow className="mt-1 mb-2">
              <MDBCol className="mt-1 mb-1" lg="12" style={{height: "100%"}}>
                  <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white" style={{height: "100%"}}>
                      <div class="centerImg">
                        <img
                        src={graph_1}
                        alt="Process Control Graph"
                        height="80%"
                        width="80%"
                        />
                      </div>
                  </MDBContainer>
              </MDBCol>
          </MDBRow>
          <MDBRow className=" mt-1">
              <MDBCol className="mt-1 mb-1" lg="12">
                <MDBContainer className=" banner square rounded-6 border border-dark px-3 py-2 bg-white">
                  <MDBRow>
                    <MDBTypography tag="h4" variant="h4-responsive">
                      3M combines Materials Science with Data Science to help you get the right pressure setting time and again
                    </MDBTypography>
                  </MDBRow>
                  <MDBRow className="mt-2 mx-2">
                    <MDBCol md="1"></MDBCol>
                    <MDBCol md="10">
                      <MDBTypography blockquote >
                        The 3M™ Scotch-Weld™ Digital Dispensing Assistant handles the complex part by factoring in ambient temperature, adhesive properties, and the uniqueness of your specific dispenser.
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol md="1"></MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
          </MDBRow>
          <MDBRow className="align-items-center">
            <MDBCol className="mt-1 mb-1 align-items-center" md="5">
              <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white">
                <MDBTypography className=" headline1 title-box mt-2">
                    The Assistant allows you to:
                </MDBTypography>
                <ul className="list-unstyled">
                    <li className="mt-0 mb-4"><i className="fa fa-angle-right"></i> Maintain consistent dispensing for changing conditions</li>
                    <li className="mb-3"><i className="fa fa-angle-right"></i> Change line speed without recalibrating</li>
                    <li className="mb-3"><i className="fa fa-angle-right"></i> Change bead size without recalibrating</li>
                    <li className="mb-3"><i className="fa fa-angle-right"></i> Change cartridges without recalibrating</li>
                </ul>
                </MDBContainer>
            </MDBCol>
            <MDBCol className="mt-1 mb-1 h-100 text-center" md="7">
              <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white">
                    <img
                    src={app}
                    alt="Mobile picture of the Assistant"
                    height="80%"
                    width="80%"
                    />
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBCol>
    </MDBRow>
    </MDBContainer>

  );
}
