import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBBadge,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTypography,
  MDBTooltip,
  MDBContainer,
} from "mdb-react-ui-kit";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import ProductService from "../../services/product";
import CalibrationService from "../../services/calibration";
import * as constants from "../../services/constants";
import lotExampleImage from "../../assets/images/lot_example.png";
import "./AddCalibration.css";

export default function AddCalibration(props) {
  let today = new Date();
  // TODO: Need to get from product source
  const navigate = useNavigate();
  const [staticModal, setStaticModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expiredWarningModal, setExpiredWarningModal] = useState(false);
  const [calibrateOnceAlertOpen, setCalibrateOnceAlertOpen] = useState(true);
  // const [continueAddCali, setContinueAddCali] = useState(false);
  const [calibrationData, setCalibrationData] = useState({
    ProductGuid: "",
    Date: today.toISOString().split("T")[0],
    Lot: "",
    LotA: "",
    LotB: "",
    AmbientTemperature: 0,
    TimeDispensed: 0,
    DataPoints: [{ tp: 0, m: 0, p: 0, tm: 0 }],
    Status: false,
  });

  const handleOkayExpiredModal = () => {
    setExpiredWarningModal(false);
    // setContinueAddCali(true);
  };
  // const handleCancelExpiredModal = () => {
  //   setExpiredWarningModal(false);
  //   // setContinueAddCali(false);
  //   setStaticModal(true);
  // }
  const toggleShow = () => {
    setStaticModal(!staticModal);
    setCalibrationData({
      ProductGuid: "",
      Date: today.toISOString().split("T")[0],
      Lot: "",
      LotA: "",
      LotB: "",
      AmbientTemperature: 0,
      TimeDispensed: 5,
      DataPoints: [{ tp: 0, m: 0, p: 0, tm: 0 }],
      Status: false,
    });
    setErrorMessage("");
  };

  // handles form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCalibrationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const lot_day_difference_from_now = (lot) => {
    let decade = "";

    if (lot[0] > "6") {
      decade = "1";
    } else {
      decade = "2";
    }
    let year = "20" + decade + lot[0];
    let day = lot.slice(1, 4);
    let lot_date = new Date(year, 0, 1);
    lot_date.setDate(lot_date.getDate() + parseInt(day));
    let difference = Math.round(
      (new Date().getTime() - lot_date.getTime()) / (1000 * 3600 * 24)
    );
    return difference;
  };

  // handles calibration data submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let lots = calibrationData.Lot.match(constants.lotRegex);
    if (!lots || lots.length != 2) {
      setErrorMessage("Please enter a valid lot string.");
      setIsLoading(false);
      return;
    }

    let lotA = lots[1].toUpperCase();
    let lotB = lots[0].toUpperCase();

    Promise.all([
      ProductService.getProductDetails(calibrationData.ProductGuid),
      ProductService.findProduct(calibrationData.ProductGuid, lotA, "A"),
      ProductService.findProduct(calibrationData.ProductGuid, lotB, "B"),
    ])
      .then(([productDetails, lotAData, lotBData]) => {
        if (Object.keys(lotAData).length === 0) {
          setIsLoading(false);
          setErrorMessage(`Lot codes not found.`);
          return;
        }

        if (Object.keys(lotBData).length === 0) {
          setIsLoading(false);
          setErrorMessage(`Lot codes not found.`);
          return;
        }
        if (
          lot_day_difference_from_now(lotA) > productDetails.ShelfLife ||
          lot_day_difference_from_now(lotB) > productDetails.ShelfLife
        ) {
          setIsLoading(false);
          setExpiredWarningModal(true);

          // setErrorMessage(`Lot codes expired.`);
        }

        let minPress = props.machineDetails.MachineInfo.MinPressure;
        let maxPress = props.machineDetails.MachineInfo.MaxPressure;
        let oneThirdPress = Math.round(
          (1 / 3) * (maxPress - minPress) + minPress
        );
        let twoThirdsPress = Math.round(
          (2 / 3) * (maxPress - minPress) + minPress
        );

        let unshuffled = [maxPress, twoThirdsPress, oneThirdPress, minPress];
        let doubled = unshuffled.flatMap((i) => [i, i]);
        let dataPoints = doubled.map((i) => ({ tp: i, p: 0, m: 0, tm: 0 }));
        let calibToSave = {
          ...calibrationData,
          DataPoints: dataPoints,
          LotA: lotA,
          LotB: lotB,
        };

        CalibrationService.saveCalibrationData(
          props.machineId,
          calibToSave
        ).then((response) => {
          if (response) {
            toggleShow();
            setIsLoading(false);
            props.addCalib(response.newCalib);
          }
        });
      })
      .catch((err) => {
        setErrorMessage(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <span onClick={toggleShow}>
        <MDBIcon role="button" fas icon="plus" />
        <label className="ms-2 fs-6" role="button">
          Add Calibration Test
        </label>
      </span>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={staticModal}
        setShow={setStaticModal}
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBRow>
              <MDBCol size="2"></MDBCol>
              <MDBCol size="8" className="px-4">
                <MDBRow className="mb-3">
                  <MDBTypography
                    className="d-flex justify-content-center component-heading mb-0"
                    tag="h4"
                  >
                    Add Calibration Test
                  </MDBTypography>
                </MDBRow>
                <MDBRow>
                  <Collapse in={calibrateOnceAlertOpen}>
                    <Alert
                      severity="info"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setCalibrateOnceAlertOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 1, fontSize: "14px" }}
                    >
                      <strong>Only one lot per adhesive</strong> is needed to calibrate this machine setup.
                      Once calibrated, you may use any lot of this adhesive with
                      the Pressure Calculator.
                    </Alert>
                  </Collapse>
                </MDBRow>
                <form
                  id="add-calibration-form"
                  className="form-inline"
                  onSubmit={handleSubmit}
                >
                  <div className="square rounded-6 border border-dark p-3 bg-white">
                    <label>Product</label>
                    <select
                      className="form-control mb-2"
                      name="ProductGuid"
                      disabled={isLoading}
                      value={calibrationData.ProductGuid}
                      onChange={handleChange}
                      required
                    >
                      <option disabled value="" key="">
                        Select Product
                      </option>
                      {props.productList.map((item) => (
                        <option value={item.PK} key={item.PK}>
                          {item.ProductName}
                        </option>
                      ))}
                    </select>
                    <MDBRow>
                      <MDBCol>
                        <label>
                          Lot Code(s)
                          <MDBTooltip
                            tag="span"
                            wrapperClass="d-inline-block cursor-pointer"
                            placement="top"
                            title={
                              <MDBContainer className="ocr-tooltip">
                                <img
                                  src={lotExampleImage}
                                  alt="Lot Codes Example"
                                  height="100%"
                                  width="100%"
                                />
                              </MDBContainer>
                            }
                          >
                            &nbsp;&nbsp;
                            <i
                              className="fas fa-info-circle fa-lg"
                              // style= {{color : '#F11523'}}
                              style={{ color: "DodgerBlue" }}
                            ></i>
                          </MDBTooltip>
                        </label>
                        <MDBInput
                          className="mb-2"
                          type="input"
                          id="lot-a"
                          required
                          name="Lot"
                          value={calibrationData.Lot}
                          onChange={handleChange}
                          disabled={isLoading}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <label>Ambient Temperature</label>
                      <MDBCol size="8">
                        <MDBInput
                          className="mb-2"
                          type="number"
                          required
                          name="AmbientTemperature"
                          onChange={handleChange}
                          step="0.01"
                          min={
                            props.unitPreferences?.Temperature?.toLowerCase() ===
                            constants.temperatureUnits.celsius.unit
                              ? 15
                              : 60
                          }
                          max={
                            props.unitPreferences?.Temperature?.toLowerCase() ===
                            constants.temperatureUnits.celsius.unit
                              ? 35
                              : 95
                          }
                          disabled={isLoading}
                        />
                      </MDBCol>
                      <MDBCol className="mt-1 ps-0">
                        <label>
                          {props.unitPreferences?.Temperature?.toLowerCase() ===
                          constants.temperatureUnits.celsius.unit
                            ? constants.temperatureUnits.celsius.name
                            : constants.temperatureUnits.fahrenheit.name}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <label>
                          Time for Dispensing
                          <MDBTooltip
                            tag="span"
                            wrapperClass="d-inline-block cursor-pointer"
                            placement="top"
                            title={
                              <MDBContainer className="ocr-tooltip">
                                Dispenser shot time for calibration process only
                                (not your production time).
                                <br></br>
                                <br></br>5 seconds is our suggested starting
                                point. Adjust if needed.
                              </MDBContainer>
                            }
                          >
                            &nbsp;&nbsp;
                            <i
                              className="fas fa-info-circle fa-lg"
                              style={{ color: "DodgerBlue" }}
                            ></i>
                          </MDBTooltip>
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="8">
                        <MDBInput
                          className="mb-2"
                          type="number"
                          name="TimeDispensed"
                          min="0.01"
                          max="999"
                          value={calibrationData.TimeDispensed}
                          onChange={handleChange}
                          step="0.01"
                          disabled={isLoading}
                        />
                      </MDBCol>
                      <MDBCol className="mt-1 ps-0">
                        <label>seconds</label>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </form>
                <MDBRow className="mt-3">
                  <MDBCol className="ps-1">
                    <MDBBtn
                      type="button"
                      onClick={toggleShow}
                      disabled={isLoading}
                      className="cancel-button"
                      block
                    >
                      Cancel
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol className="pe-1">
                    <MDBBtn
                      type="submit"
                      form="add-calibration-form"
                      disabled={isLoading}
                      block
                    >
                      Submit
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size="2"></MDBCol>
            </MDBRow>
            {errorMessage !== "" ? (
              <MDBRow>
                <MDBCol size="2"></MDBCol>
                <MDBCol className="px-4 mt-2">
                  <MDBTypography note className="mt-2" noteColor="danger">
                    <strong>Error:</strong> {errorMessage}
                  </MDBTypography>
                </MDBCol>
                <MDBCol size="2"></MDBCol>
              </MDBRow>
            ) : (
              ""
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal show={expiredWarningModal} staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
            <MDBModalHeader>
              <b>Warning</b>
            </MDBModalHeader>
            <MDBModalBody>
              Lot codes are expired. Product specifications do not apply to expired products, and calibration accuracy may be reduced.
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn className="cancel-button" color="primary" size="sm" onClick={handleCancelExpiredModal}>
                  Cancel
                </MDBBtn> */}
              <MDBBtn
                className="delete-button"
                size="sm"
                onClick={handleOkayExpiredModal}
              >
                Okay
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
