import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./Footer.css";
import faqPDF from "../../../FAQs.pdf";
import instructionPDF from "../../../User_Instructions.pdf";
import termsPDF from "../../../TermsOfUse.pdf";

const Footer = () => {
  const { userData } = useSelector((state) => state);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (!userData || !userData.isLoggedIn) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);
  }, [userData, isLoggedIn]);

  if (isLoggedIn){
    return (
      <div className="footer">
        <span>
        © 3M {new Date().getFullYear()} All Rights Reserved.
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://3m.com/" rel="noreferrer" target="_blank">3M.com</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href='https://www.3m.com/3M/en_US/company-us/legal-information/' rel="noreferrer" target="_blank">Legal Information</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href='https://www.3m.com/3M/en_US/company-us/privacy-policy/' rel="noreferrer" target="_blank">Privacy Policy</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href={termsPDF} rel="noreferrer" target="_blank">Terms of Use</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href={instructionPDF} rel="noreferrer" target="_blank">User Instructions</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href={faqPDF} rel="noreferrer" target="_blank">FAQs</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="mailto: 3MDigitalDispensingAssistant@mmm.com">Contact Us</a>
        </span>
      </div>
    );
  } 
  else {
    return (
      <div className="footer">
        <span>
        © 3M {new Date().getFullYear()} All Rights Reserved.
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://3m.com/" rel="noreferrer" target="_blank">3M.com</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href='https://www.3m.com/3M/en_US/company-us/legal-information/' rel="noreferrer" target="_blank">Legal Information</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href='https://www.3m.com/3M/en_US/company-us/privacy-policy/' rel="noreferrer" target="_blank">Privacy Policy</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href={termsPDF} rel="noreferrer" target="_blank">Terms of Use</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="mailto: 3MDigitalDispensingAssistant@mmm.com">Contact Us</a>
        </span>
      </div>
    );
  }
};

export default Footer;
