// units for pressure
export const pressureUnits = {
    kpa: {
        unit: 'kpa',
        name: 'kPa'
    },
    psi: {
        unit: 'psi',
        name: 'psi'
    }
}

// units for temperature
export const temperatureUnits = {
    celsius: {
        unit: 'c',
        name: 'Celsius'
    },
    fahrenheit: {
        unit: 'f',
        name: 'Fahrenheit'
    }
}

// units for weight
export const weightUnits = {
    milliliter: {
        unit: 'ml',
        name: 'milliliter'
    },
    gram: {
        unit: 'g',
        name: 'gram'
    },
    crossSection: {
        unit: 'mm^2',
        name: 'cross sectional area (mm^2 and mm/s)'
    }
}

// units for time
export const timeUnits = {
    seconds: {
        unit: 's',
        name: 'seconds'
    },
    headSpeed: {
        unit: 'mm/s',
        name: 'millimeter per second'
    }
}

export const scopes = {
    canViewMachineSetup: 'can-view-machine-setup',
    canViewMachineDetails: 'can-view-machine-details',
    canViewPressureCalculator: 'can-view-pressure-calculator',
    canViewStoryboard: 'can-view-storyboard'
};

export const cognitoAttributes = {
    tenantId: 'custom:tenant_id',
    userRole: 'custom:user_role',
    email: 'email'
}

export const roles = {
    engineer: 'ENGINEER',
    admin: 'ADMIN',
    operator: 'OPERATOR',
}

export const permissions = {
    [roles.engineer]: [
        scopes.canViewMachineDetails,
        scopes.canViewMachineSetup,
        scopes.canViewPressureCalculator,
        scopes.canViewStoryboard
    ],
    [roles.operator]: [
        scopes.canViewPressureCalculator
    ],
    [roles.admin]: [
        scopes.canViewMachineDetails,
        scopes.canViewMachineSetup,
        scopes.canViewPressureCalculator,
        scopes.canViewStoryboard
    ]
};

export const tenantAttribute = 'custom:tenant_id'
export const roleAttribute = 'custom:user_role'
export const userNameAttribute = 'cognito:username'
export const userEmailAttribute = 'email'
export const accessTokenAttribute = 'accessToken'
export const idTokenAttribute = 'idToken'
export const refreshTokenAttribute = 'refreshToken'
export const lotRegex = /[0-9]{4}[a-zA-Z]/g