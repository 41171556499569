import React, { useState, useEffect } from "react"
import { useTransition, animated } from "react-spring"
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import CalculatePressure from "../../components/CalculatePressure"
import SelectedProduct from "../../components/SelectedProduct"
import FindProduct from "../../components/FindProduct"
import {
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdb-react-ui-kit"
import { CheckPermission } from "../../components/CheckPermission"
import * as constants from '../../services/constants'

export default function PressureCalculator(props) {
    const [productData, setProductData] = useState({})
    const [isProductSelected, handleProductSelect] = useState(false)
    const [isProductExpired, setIsProductExpired] = useState(false)

    const transition = useTransition(isProductSelected, {
        from: { opacity: 0, y: 100 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 100 },
        reverse: !isProductSelected,
        trail: 1000,
        expires: 10
    })
    const { userData } = useSelector(state => state)
    const navigate = useNavigate()

    useEffect(() => {
        if (!userData || !userData.isLoggedIn) {
            navigate('/')
            return
        }
    }, [userData, navigate])

    // This is to refresh the page after idle for 30 minutes
    let time = new Date().getTime();
    const setActivityTime = (e) => {
      time = new Date().getTime();
    }
    document.body.addEventListener("mousemove", setActivityTime);
    document.body.addEventListener("keypress", setActivityTime);
    const refresh = () => {
      if (new Date().getTime() - time >= 1800000) {
        window.location.reload(true);
      } 
    //   else {
    //     setTimeout(refresh, 10000);
    //   }
    }
    setTimeout(refresh, 10000);

    return (
        <>
            {
                userData.isLoggedIn ?
                    <CheckPermission permission={constants.scopes.canViewPressureCalculator} isComponent={true}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol className="mt-3" lg="1" md="12"></MDBCol>
                                <MDBCol className="mt-3" lg="5" md="6">
                                    {
                                        transition((style, item) =>
                                            !item ?
                                                <animated.div style={style}>
                                                    <FindProduct setProductData={setProductData}
                                                        isProductSelected={handleProductSelect}
                                                        isProductExpired={setIsProductExpired}>
                                                    </FindProduct>
                                                </animated.div> :
                                                <animated.div style={style}>
                                                    <SelectedProduct productData={productData}
                                                        isProductSelected={handleProductSelect}
                                                        isProductExpired={isProductExpired}
                                                        >
                                                    </SelectedProduct>
                                                </animated.div>
                                        )
                                    }
                                </MDBCol>
                                <MDBCol className="mt-3" lg="5" md="6">
                                    <CalculatePressure isProductSelected={isProductSelected}
                                        productData={productData}>
                                    </CalculatePressure>
                                </MDBCol>
                                <MDBCol className="mt-3" lg="1" md="12"></MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </CheckPermission> : ''
            }
        </>
    )
}