import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { AccountContext } from "../Accounts";
import * as constants from "../../services/constants";
import { save } from "../../services/userDataSlice";
import { useDispatch } from "react-redux";

export default function ChangePassword(props) {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { getSession, authenticate } = useContext(AccountContext);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const dispatch = useDispatch();

  // a list of change password instruction
  const handleConfirmSuccessful = () => {
    navigate("/machine-setup");
  };

  let list = (
    <ul>
      <li>12-character minimum length</li>
      <li>Contains at least 1 number</li>
      <li>
        {
          "Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & /  , > < ' : ; | _ ~ ` + = )"
        }
      </li>
      <li>Contains at least 1 lowercase letter</li>
      <li>Contains at least 1 uppercase letter</li>
    </ul>
  );

  const changePassword = (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);

    if (newPassword !== confirmNewPassword) {
      setIsLoading(false);
      setErrorMessage("Passwords do not match!");
      return;
    }

    if (props.isFirstLogin) {
      const cognitoUser = props.user;
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: (_) => {
            getSession()
              .then((session) => {
                let userRole =
                  session.idToken.payload[
                    constants.cognitoAttributes.userRole
                  ].toUpperCase();
                dispatch(
                  save({
                    role: userRole,
                    email:
                      session.idToken.payload[
                        constants.cognitoAttributes.email
                      ],
                    tenantId:
                      session.idToken.payload[
                        constants.cognitoAttributes.tenantId
                      ],
                    isLoggedIn: true,
                  })
                );

                if (userRole === constants.roles.operator) {
                  navigate("/pressure-calculator");
                } else {
                  navigate("/machine-setup");
                }
              })
              .catch((_) => {
                navigate("/");
              });
          },
          onFailure: (result) => {
            setIsLoading(false);
            setErrorMessage(result.message ? result.message : result);
          },
        }
      );
    } else {
      getSession().then(({ user, email }) => {
        authenticate(email, oldPassword).then(() => {
          user.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
              setErrorMessage(err.message ? err.message : err);
              setIsLoading(false);
              return;
            }
            // setSuccessMessage("Password changed successfully!");
            setIsLoading(false);
            setPasswordChangeModal(true);
          });
        });
      });
    }
  };

  return (
    <MDBContainer className="mt-5">
      <MDBTypography className="text-center component-heading" tag="h4">
        Change Password
      </MDBTypography>
      <MDBRow>
        <MDBCol lg="4" md="3" xs="12"></MDBCol>
        <MDBCol>
          <form
            id="change-password-form"
            onSubmit={changePassword}
            className="square rounded-6 border border-dark px-3 py-2 bg-white"
          >
            {!props.isFirstLogin ? (
              <>
                <MDBRow>
                  <MDBCol>
                    <label className="d-flex justify-content-start">
                      Old Password*
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBInput
                  className="mb-2"
                  type="password"
                  id="old-password"
                  disabled={isLoading}
                  onChange={(event) => setOldPassword(event.target.value)}
                  name="OldPassword"
                  required
                />
              </>
            ) : (
              ""
            )}

            <MDBRow>
              <MDBCol>
                <label className="d-flex justify-content-start">
                  New Password*
                </label>
              </MDBCol>
            </MDBRow>
            <MDBInput
              className="mb-2"
              type="password"
              id="new-password"
              disabled={isLoading}
              onChange={(event) => setNewPassword(event.target.value)}
              name="NewPassword"
              required
            />
            <MDBRow>
              <MDBCol>
                <label className="d-flex justify-content-start">
                  Confirm New Password*
                </label>
              </MDBCol>
            </MDBRow>
            <MDBInput
              className="mb-2"
              type="password"
              id="confirm-new-password"
              disabled={isLoading}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
              name="ConfirmNewPassword"
              required
            />
          </form>
          <MDBBtn
            form="change-password-form"
            type="submit"
            className="mt-2"
            block
          >
            Change Password
          </MDBBtn>
          {errorMessage !== "" ? (
            <MDBTypography note noteColor="danger" className="mt-2">
              <strong>Error:</strong> {errorMessage}
            </MDBTypography>
          ) : (
            ""
          )}
          {successMessage !== "" ? (
            <MDBTypography note noteColor="success" className="mt-2">
              {successMessage}
            </MDBTypography>
          ) : (
            ""
          )}
          {isLoading ? (
            <MDBContainer className="text-center mt-3">
              <MDBSpinner className="calculating-spinner">
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            </MDBContainer>
          ) : (
            ""
          )}
        </MDBCol>
        <MDBCol lg="4" md="3" xs="12">
          <MDBTypography style={{ fontSize: 12 }} tag="h6">
            Password policy
          </MDBTypography>
          {list}
        </MDBCol>
      </MDBRow>
      <MDBModal show={passwordChangeModal} staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
            <MDBModalHeader>
              <b>Message</b>
            </MDBModalHeader>
            <MDBModalBody>Password change successful!</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="okay-button"
                size="sm"
                onClick={handleConfirmSuccessful}
              >
                Okay
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
}
