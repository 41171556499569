import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import "./App.css";
import Layout from "./components/Layout/Layout";
import { Account } from "./components/Accounts";
import Home from "./views/Home/Home";
import MachineSetup from "./views/MachineSetup/MachineSetup";
import MachineDetails from "./views/MachineDetails/MachineDetails";
import PressureCalculator from "./views/PressureCalculator/PressureCalculator";
import Storyboard from "./views/Storyboard/Storyboard";
import Unauthorized from "./views/Unauthorized/Unauthorized";
import UserProfile from "./views/UserProfile/UserProfile";
import ChangePassword from "./components/ChangePassword";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState();
  return (
    <>
      <Account>
        <Layout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}>
          <MDBRow className="d-flex justify-content-center">
            <MDBCol md="12" lg="8" className="mt-1">
              <Routes>
                <Route
                  path="/machine-setup"
                  element={
                    <MachineSetup
                      isLoggedIn={isLoggedIn}
                      setIsLoggedIn={setIsLoggedIn}
                    />
                  }
                />
                <Route
                  path="/machine-details"
                  element={
                    <MachineDetails
                      isLoggedIn={isLoggedIn}
                      setIsLoggedIn={setIsLoggedIn}
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    <Home
                      isLoggedIn={isLoggedIn}
                      setIsLoggedIn={setIsLoggedIn}
                    />
                  }
                />
                <Route
                  path="/pressure-calculator"
                  element={
                    <PressureCalculator
                      isLoggedIn={isLoggedIn}
                      setIsLoggedIn={setIsLoggedIn}
                    />
                  }
                />
                <Route path="/storyboard" element={<Storyboard />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/user-profile" element={<UserProfile />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Routes>
            </MDBCol>
          </MDBRow>
        </Layout>
      </Account>
    </>
  );
}

export default App;
