import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBModal,
  MDBInput,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCol,
  MDBSpinner,
  MDBRow,
} from "mdb-react-ui-kit";
import * as constants from "../../services/constants";
import CalibrationService from "../../services/calibration";
import "./CalibrationDetails.css";

export default function CalibrationDetails(props) {
  const [dataPoints, setDataPoints] = useState([
    {
      tp: 0,
      tm: 0,
      m: 0,
      p: 0,
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showInvalidDataModal, setShowInvalidDataModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setDataPoints(props.calibrationData.DataPoints);
  }, [props]);

  // adds new data points for mass and pressure
  const addDataPoint = (newPress) => {
    setDataPoints((prevDataPoints) => [
      ...prevDataPoints,
      { tp: newPress, p: null, m: null, tm: null },
    ]);
  };

  // handles mass data point change
  const handleChangeMass = (e, index) => {
    const tempDataPoints = [...dataPoints];
    tempDataPoints[index].m = e.target.value;
    setDataPoints(tempDataPoints);
  };

  // handles pressure data point change
  const handleChangePressure = (e, index) => {
    const temp = [...dataPoints];
    temp[index].p = e.target.value;
    setDataPoints(temp);
  };
  const checkValidData = (calibrationData) => {
    let isInvalid = false;
    for (let i = 0; i < calibrationData.DataPoints.length; i++) {
      let tempDatapoint = calibrationData.DataPoints[i];
      if (parseFloat(tempDatapoint.m) === 0 || parseFloat(tempDatapoint.p) === 0) {
        isInvalid = true;
      }
    }
    return isInvalid;
  };
  // saves calibration details
  const saveCalibrationDetails = () => {
    setIsVerifying(true);
    let tempCalibrationData = props.calibrationData;
    tempCalibrationData.DataPoints = dataPoints;
    let isInvalid = checkValidData(tempCalibrationData);

    console.log(isInvalid);
    // Verify and save calibration details API call
    if (!isInvalid) {
      CalibrationService.verifyCalibration(
        props.calibrationData.SK,
        tempCalibrationData
      )
        .then((response) => {
          if (response.calibration_verified !== undefined) {
            if (response.calibration_verified) {
              setShowModal(true);
              setModalMessage(
                'Calibration successful. Please go to "Pressure Calculator" to use this machine setup.'
              );
              setIsSuccessful(true);
              setIsVerifying(false);
            } else {
              // User needs to retake some data points
              if (response.retake_points.length > 0) {
                setShowModal(true);
                setModalMessage(
                  "Calibration unsuccessful. Please check inputs for typos or restart the process following the instructions."
                );
                setIsSuccessful(false);
              }
              // Calibration lambda returned proper format, but it failed with no extra indication
              else {
                setShowModal(true);
                setModalMessage(
                  "Calibration unsuccessful. Please check inputs for typos or restart the process following the instructions."
                );
                setIsSuccessful(false);
              }
              setIsVerifying(false);
            }
          }

          // Calibration returned improper format
          else {
            setShowModal(true);
            setModalMessage(
              "Something went wrong on our end. Please contact us at 3MDigitalDispensingAssitant@mmm.com"
            );
            setIsSuccessful(false);
            setIsVerifying(false);
          }
        })
        // Lambda returned wrong thing or our API failed somewhere
        .catch((err) => {
          setShowModal(true);
          setModalMessage(
            "Something went wrong on our end. Please contact us at 3MDigitalDispensingAssitant@mmm.com"
          );
          setIsSuccessful(false);
          setIsVerifying(false);
        });
    } else {
      setShowInvalidDataModal(true);
      setIsVerifying(false);
      setIsSuccessful(false);
    }
  };

  const reloadMachine = () => {
    navigate("/machine-details", {
      state: {
        machineId: props.machineDetails.PK,
      },
    });
  };
  const handleCancel = () => {
    setShowModal(false);
    reloadMachine();
  };
  const handleDeleteOnClick = () => {
    setShowDelModal(true);
  };
  const handleDelCancel = () => {
    setShowDelModal(false);
  };
  const handleInvalidModalOkay = () => {
    setShowInvalidDataModal(false);
  };

  const deleteCalibration = () => {
    CalibrationService.deleteMachineCalibration(
      props.calibrationData.SK,
      props.calibrationData.PK
    ).then((response) => {
      if (response) {
        props.onDelete(props.calibrationData.PK);
      }
    });
  };

  if (!props.calibrationData) {
    return <>Loading...</>;
  } else {
    return (
      <>
        <MDBTable small className="calibration-details">
          <MDBTableHead>
            <tr>
              <th scope="col">
                Target Pressure ({props.machineDetails.UnitPreferences.Pressure}
                )
              </th>
              <th scope="col">
                Actual Pressure ({props.machineDetails.UnitPreferences.Pressure}
                )
              </th>
              <th scope="col">
                Mass Dispensed ({constants.weightUnits.gram.unit})
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {dataPoints?.map((_, index) => (
              <tr key={index}>
                <td>
                  <label>{dataPoints[index].tp}</label>
                </td>
                <td>
                  {props.calibrationData.Status ? (
                    <input
                      type="number"
                      // min="0"
                      onChange={(e) => handleChangePressure(e, index)}
                      value={dataPoints[index].p}
                      disabled={isVerifying}
                      step="0.01"
                      required
                    ></input>
                  ) : dataPoints[index].p === 0 ? (
                    <input
                      type="number"
                      onChange={(e) => handleChangePressure(e, index)}
                      placeholder={0}
                      step="0.01"
                      disabled={isVerifying}
                      required
                    ></input>
                  ) : (
                    <input
                      type="number"
                      step="0.01"
                      onChange={(e) => handleChangePressure(e, index)}
                      value={dataPoints[index].p}
                      disabled={isVerifying}
                      required
                    ></input>
                  )}
                </td>
                <td>
                  {props.calibrationData.Status ? (
                    <input
                      type="number"
                      onChange={(e) => handleChangeMass(e, index)}
                      value={dataPoints[index].m}
                      step="0.01"
                      disabled={isVerifying}
                      required
                    ></input>
                  ) : dataPoints[index].m === 0 ? (
                    <input
                      type="number"
                      step="0.01"
                      onChange={(e) => handleChangeMass(e, index)}
                      placeholder={0}
                      disabled={isVerifying}
                      required
                    ></input>
                  ) : (
                    <input
                      type="number"
                      step="0.01"
                      onChange={(e) => handleChangeMass(e, index)}
                      value={dataPoints[index].m}
                      disabled={isVerifying}
                      required
                    ></input>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <MDBBtn
                  className="calibration-delete"
                  onClick={handleDeleteOnClick}
                  size="sm"
                  block
                  disabled={isVerifying}
                >
                  Delete Calibration
                </MDBBtn>
              </td>
              <td>
                {isVerifying ? (
                  <MDBCol className="text-center mt-4">
                    <MDBSpinner className="calculating-spinner">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  </MDBCol>
                ) : (
                  ""
                )}
              </td>
              <td colSpan="1">
                <MDBBtn
                  onClick={saveCalibrationDetails}
                  size="sm"
                  block
                  disabled={isVerifying}
                >
                  Save and Verify Calibration
                </MDBBtn>
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
        <MDBModal show={showModal} staticBackdrop>
          <MDBModalDialog>
            <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
              <MDBModalHeader>
                <b>Message</b>
              </MDBModalHeader>
              <MDBModalBody>{modalMessage}</MDBModalBody>
              <MDBModalFooter>
                {isSuccessful ? (
                  <MDBBtn color="primary" size="sm" onClick={reloadMachine}>
                    Close
                  </MDBBtn>
                ) : (
                  <MDBBtn color="primary" size="sm" onClick={handleCancel}>
                    Close
                  </MDBBtn>
                )}
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal show={showDelModal} staticBackdrop>
          <MDBModalDialog>
            <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
              <MDBModalHeader>
                <b>Message</b>
              </MDBModalHeader>
              <MDBModalBody>
                Press Delete to confirm the deletion of this calibration. This
                action cannot be undone.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="cancel-button"
                  color="primary"
                  size="sm"
                  onClick={handleDelCancel}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn
                  className="delete-button"
                  size="sm"
                  onClick={deleteCalibration}
                >
                  Delete
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal show={showInvalidDataModal} staticBackdrop>
          <MDBModalDialog>
            <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
              <MDBModalHeader>
                <b>Message</b>
              </MDBModalHeader>
              <MDBModalBody>
                Calibration data incomplete. Please fill in all fields.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="cancel-button"
                  color="primary"
                  size="sm"
                  onClick={handleInvalidModalOkay}
                >
                  Confirm
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    );
  }
}
