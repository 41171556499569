import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flags } from "react-feature-flags";
import {
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBTooltip,
  MDBIcon,
} from "mdb-react-ui-kit";
import ProductService from "../../services/product";
import OCRScanner from "../OCRScanner";
import * as constants from "../../services/constants";
import barcodeTemplate from "../../assets/images/dummy_barcode.gif";
import lotExampleImage from "../../assets/images/lot_example.png";
import "./FindProduct.css";

export default function FindProduct(props) {
  const [productList, setProductList] = useState([]);
  const [productInfo, setProductInfo] = useState({
    Product: {},
    LotA: "",
    LotB: "",
    ExpiryDate: "",
    Lot: "",
    ProductGuid: "",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOCR, setIsLoadingOCR] = useState(false);
  const [showOCR, setShowOCR] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [scanResponse, setScanResponse] = useState({
    ProductId: "",
    LotA: "",
    LotB: "",
    Message: "",
  });

  /* Storing user's device details in a variable*/
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);

  // if (isMobileDevice) {
  //   document.write("You are using a Mobile Device");
  // } else {
  //   document.write("You are using Desktop");
  // }

  // gets initial data
  useEffect(() => {
    setIsLoading(true);
    setErrorMessage(!scanResponse.Message ? "" : scanResponse.Message);
    ProductService.getProductsByTenant()
      .then((items) => {
        setProductList(items);
        if (scanResponse.ProductId !== "") {
          const product = productList.find((p) => {
            return p.ProductBarcodes.includes(scanResponse.ProductId);
          });
          setProductInfo({
            LotA: scanResponse.LotA,
            LotB: scanResponse.LotB,
            Lot: scanResponse.LotB + scanResponse.LotA,
            ProductGuid: !product ? "" : product.ProductGuid,
            Product: !product ? {} : product,
          });
        } else {
          setProductInfo({
            LotA: scanResponse.LotA,
            LotB: scanResponse.LotB,
            Lot: scanResponse.LotB + scanResponse.LotA,
            ProductGuid: "",
            Product: {}
          })
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Unauthorized") {
          navigate("/unauthorized");
        }
      });
  }, [scanResponse, navigate]);

  // handles form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handles product dropdown changes
  const handleChangeProduct = (e) => {
    const { name, value } = e.target;
    const product = productList.find((p) => {
      return p.ProductGuid === value;
    });

    setProductInfo((prevState) => ({
      ...prevState,
      [name]: !product ? "" : product,
      ProductGuid: !product ? "" : product.ProductGuid,
    }));
  };
  const lot_day_difference_from_now = (lot) => {
    let decade = ""
  
    if (lot[0] > "6"){
      decade = "1";
    }
    else{
      decade = "2";
    };
    let year = "20" + decade + lot[0];
    let day = lot.slice(1, 4);    
    let lot_date = new Date(year, 0, 1);
    lot_date.setDate(lot_date.getDate() + parseInt(day))
    let difference = Math.round((new Date().getTime() - lot_date.getTime()) / (1000 * 3600 * 24))
    return difference;
  };
  // handles find product
  const handleSubmit = (event) => {
    setErrorMessage("");
    setIsLoading(true);
    event.preventDefault();
    let lots = productInfo.Lot.match(constants.lotRegex);
    if (!lots || lots.length !== 2) {
      setErrorMessage("Please enter a valid lot string.");
      setIsLoading(false);
      return;
    }

    let lotA = lots[1].toUpperCase();
    let lotB = lots[0].toUpperCase();

    setProductInfo((prevState) => ({
      ...prevState,
      LotA: lotA,
      LotB: lotB,
    }));

    Promise.all([
      ProductService.getProductDetails(productInfo.Product.ProductGuid),
      ProductService.findProduct(productInfo.Product.ProductGuid, lotA, "A"),
      ProductService.findProduct(productInfo.Product.ProductGuid, lotB, "B"),
    ])
      .then(([productDetails, lotAData, lotBData]) => {
        if (Object.keys(lotAData).length === 0) {
          setIsLoading(false);
          setErrorMessage(`Lot codes not found.`);
          props.isProductSelected(false);
          return;
        }

        if (Object.keys(lotBData).length === 0) {
          setIsLoading(false);
          setErrorMessage(`Lot codes not found.`);
          props.isProductSelected(false);
          return;
        }
        if (lot_day_difference_from_now(lotA) > productDetails.ShelfLife || lot_day_difference_from_now(lotB) > productDetails.ShelfLife){
          setIsLoading(false);
          props.isProductExpired(true);
        }
        else{
          props.isProductExpired(false);
        }

        props.setProductData({
          lotAInfo: lotAData,
          lotBInfo: lotBData,
          productInfo: productDetails,
        });
        setIsLoading(false);
        props.isProductSelected(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMessage(err);
      });
  };

  return (
    <MDBContainer className="find-product-container px-0">
      <MDBTypography className="text-center component-heading" tag="h4">
        Enter Product
      </MDBTypography>
      {!isLoading && productList.length > 0 ? (
        <>
          <form
            className="form-inline square rounded-6 border border-dark p-3 bg-white"
            onSubmit={handleSubmit}
            id="form-find-product"
          >
            <label>Product</label>
            <select
              disabled={isLoadingOCR}
              className="form-control mb-2"
              name="Product"
              value={productInfo.ProductGuid}
              onChange={handleChangeProduct}
              required
            >
              <option disabled value="" key="">
                Select Product
              </option>
              {productList.map((item) => (
                <option value={item.ProductGuid} key={item.ProductGuid}>
                  {item.ProductName}
                </option>
              ))}
            </select>
            <MDBRow className="mb-2">
              <MDBCol>
                <label>
                  Lot Code(s)
                  <MDBTooltip
                    tag="span"
                    wrapperClass="d-inline-block cursor-pointer"
                    placement="top"
                    title={
                      <MDBContainer className="ocr-tooltip">
                        <img
                          src={lotExampleImage}
                          alt="Lot Codes Example"
                          // height="80%"
                          width="90%"
                        />
                      </MDBContainer>
                    }
                  >
                    &nbsp;&nbsp;
                    <i
                      className="fas fa-info-circle fa-lg"
                      // style= {{color : '#F11523'}}
                      style={{ color: "DodgerBlue" }}
                    ></i>

                  </MDBTooltip>
                </label>
                <MDBInput
                  className="mb-2"
                  type="input"
                  id="lot-a"
                  required
                  name="Lot"
                  value={productInfo.Lot}
                  onChange={handleChange}
                  disabled={isLoadingOCR}
                />
              </MDBCol>
              <Flags authorizedFlags={["ocr-scan"]}>
                {isMobileDevice ? (
                  <MDBCol size="2" className="mt-3">
                    <MDBTooltip
                      tag="span"
                      wrapperClass="d-inline-block cursor-pointer"
                      placement="top"
                      title={
                        <MDBContainer className="ocr-tooltip">
                          <MDBTypography tag="small" className="text-muted">
                            Scan product information using camera
                          </MDBTypography>
                          <img
                            src={barcodeTemplate}
                            alt="Barcode Template"
                            height="70"
                            width="150"
                          />
                        </MDBContainer>
                      }
                    >
                      <MDBIcon
                        className="mt-2 cursor-pointer"
                        fas
                        icon="camera"
                        size="2x"
                        role="button"
                        onClick={() => {
                          setErrorMessage("");
                          setShowOCR(!showOCR);
                        }}
                      />
                    </MDBTooltip>
                  </MDBCol>
                ) : (
                  ""
                )}
              </Flags>
            </MDBRow>
          </form>
          {!showOCR ? (
            <MDBBtn
              type="submit"
              form="form-find-product"
              className="mt-3"
              block
            >
              Submit
            </MDBBtn>
          ) : (
            <OCRScanner
              setShowOCR={setShowOCR}
              setScanResponse={setScanResponse}
              isLoadingOCR={isLoadingOCR}
              setIsLoadingOCR={setIsLoadingOCR}
            ></OCRScanner>
          )}
        </>
      ) : !isLoading && productList.length === 0 ? (
        <MDBContainer className="square rounded-6 border border-dark p-3 bg-white text-center">
          No products have been calibrated.
        </MDBContainer>
      ) : (
        <MDBContainer className="square rounded-6 border border-dark p-3 bg-white text-center">
          <MDBSpinner className="calculating-spinner">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </MDBContainer>
      )}
      {errorMessage !== "" ? (
        <MDBTypography note className="mt-2" noteColor="danger">
          <strong>Error:</strong> {errorMessage}
        </MDBTypography>
      ) : (
        ""
      )}
    </MDBContainer>
  );
}