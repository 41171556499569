import React from "react"
import LoginForm from "../../components/Login"

const Home = () => {
  return (
    <>
      <LoginForm />
    </>
  )
}

export default Home;
