import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../UserPool";

const AccountService = (function () {
    const getSession = async () =>
        await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser()
            if (user) {
                user.getSession(async (err, session) => {
                    if (err) {
                        reject()
                    } else {
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err)
                                } else {
                                    const results = {}

                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute
                                        results[Name] = Value
                                    }

                                    resolve(results)
                                }
                            })
                        })

                        resolve({
                            user,
                            ...session,
                            ...attributes
                        })
                    }
                })
            } else {
                reject()
            }
        })


    const authenticate = async (Username, Password) =>
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool })
            const authDetails = new AuthenticationDetails({ Username, Password })

            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    resolve(data)
                },

                onFailure: err => {
                    reject(err)
                },

                newPasswordRequired: userAttr => {
                    resolve({
                        isFirstLogin: true,
                        user: user,
                        userAttr: userAttr,
                    })
                }
            })
        })


    const logout = () => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.signOut()
        }
    }

    const getUser = (email) => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool
        });
    };

    return {
        logout: logout,
        authenticate: authenticate,
        getSession: getSession,
        getUser: getUser
    }

})()

export default AccountService