import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBTypography,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBSpinner,
} from "mdb-react-ui-kit";
import Alert from "@mui/material/Alert";
import MachineService from "../../services/machine";
import PressureCalculatorService from "../../services/pressurecalc";
import { useNavigate } from "react-router-dom";
import { MDBTooltip } from "mdb-react-ui-kit";
import PurgingSuggestion from "../PurgingSuggestion";
import faqPDF from "../../FAQs.pdf";
import * as constants from "../../services/constants";
import "./CalculatePressure.css";

export default function CalculatePressure(props) {
  // constant value for pressure unit conversion
  const conversionValue = 6.89476;
  const pressureUnits = {
    kpa: "kPa",
    psi: "psi",
  };

  // initialize machine dropdown
  const [calculatedPressure, setCalculatedPressure] = useState({
    pressure: "",
    unit: "",
    error: "",
    status: "",
  });

  // initialize form data
  const [userData, setUserData] = useState({
    Machine: {},
    Time: "",
    Mass: "",
    Temperature: "",
    SelectedMachineIndex: "-1",
  });

  const [learnMore, setLearnMore] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPressureUnit, setSelectedPressureUnit] = useState("");
  const [pressureDisplayValue, setPressureDisplayValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [massUnit, setMassUnit] = useState("g");
  const [timeUnit, setTimeUnit] = useState("s");
  const navigate = useNavigate();
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setUserData((prevState) => ({
      ...prevState,
      ["Time"]: "",
      ["Mass"]: "",
    }));
    setBasicActive(value);
  };
  useEffect(() => {
    setIsLoading(true);
    // set initial form data
    setUserData({
      Machine: {},
      Time: "",
      Mass: "",
      Temperature: "",
      SelectedMachineIndex: "-1",
    });

    // set initial values for calculated pressure
    setCalculatedPressure({
      pressure: "",
      unit: "",
      error: "",
    });

    setPressureDisplayValue("");
    setSelectedPressureUnit("");
    setErrorMessage("");

    MachineService.getMachineList()
      .then((items) => {
        // console.log(items)
        setMachineList(items);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Unauthorized") {
          navigate("/unauthorized");
        }
      });
  }, [props, navigate]);

  // handles form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    // set preffered unit for pressure
    if (name === "Machine") {
      setSelectedPressureUnit(machineList[value].UnitPreferences?.Pressure);
      let weightPref = machineList[value].UnitPreferences?.Weight;
      if (weightPref === "g") {
        setBasicActive("tab1");
        setMassUnit("g");
        setTimeUnit("s");
      } else if (weightPref === "ml") {
        setBasicActive("tab2");
        setMassUnit("ml");
        setTimeUnit("s");
      } else if (weightPref === "mm^2") {
        setBasicActive("tab3");
        setMassUnit("mm^2");
        setTimeUnit("mm/s");
      }
      setUserData({
        Machine: {},
        Time: "",
        Mass: "",
        Temperature: "",
        SelectedMachineIndex: value,
      });

      setUserData((prevState) => ({
        ...prevState,
        [name]: machineList[value],
      }));
      return;
    }
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handles changes in the pressure unit
  const handlePressureUnitChange = (event) => {
    let tempPressureUnit = event.target.value;
    // set new pressure unit
    setSelectedPressureUnit(tempPressureUnit);

    // if pressure unit changed, convert pressure value
    if (
      calculatedPressure.unit !== "" &&
      calculatedPressure.unit !== tempPressureUnit
    ) {
      convertPressureUnit(
        calculatedPressure.unit,
        tempPressureUnit,
        calculatedPressure.pressure
      );
      return;
    }

    setPressureDisplayValue(calculatedPressure.pressure);
  };
  const handleUnitClick = (e) => {
    // console.log(e.target.value);
  };
  const convertPressureUnit = (oldUnit, newUnit, pressureValue) => {
    if (oldUnit === pressureUnits.kpa && newUnit === pressureUnits.psi) {
      setPressureDisplayValue(pressureValue / conversionValue);
      return;
    }

    if (oldUnit === pressureUnits.psi && newUnit === pressureUnits.kpa) {
      setPressureDisplayValue(pressureValue * conversionValue);
      return;
    }
  };

  // handles machine setup form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCalculatedPressure({
      pressure: "",
      unit: "",
      error: "",
      status: "",
    });
    setPressureDisplayValue("");
    setErrorMessage("");
    setIsCalculating(true);

    let inputData = {
      user_data: {
        temperature: {
          value: userData.Temperature,
          unit: userData.Machine?.UnitPreferences?.Temperature,
        },
        time: {
          value: userData.Time,
          unit: timeUnit,
        },
        mass: {
          value: userData.Mass,
          unit: massUnit,
        },
      },
      machine_data: userData.Machine,
      product_data: props.productData.productInfo,
      lot_data: {
        lotA: props.productData.lotAInfo,
        lotB: props.productData.lotBInfo,
      },
    };
    // TODO: Display response
    let pressureCalculateResult =
      await PressureCalculatorService.calculatePressure(inputData);
    setCalculatedPressure(pressureCalculateResult);

    if (pressureCalculateResult === null) {
      setErrorMessage("Please check the lambda logs.");
      setIsCalculating(false);
      return;
    } else if (!pressureCalculateResult.status) {
      if (pressureCalculateResult.msg === undefined) {
        setErrorMessage("Please check the lambda logs.");
      } else {
        setErrorMessage(pressureCalculateResult.msg);
      }
      setIsCalculating(false);
      return;
    }

    if (
      pressureCalculateResult.unit !== "" &&
      pressureCalculateResult.unit !== selectedPressureUnit
    ) {
      convertPressureUnit(
        pressureCalculateResult.unit,
        selectedPressureUnit,
        pressureCalculateResult.pressure
      );
      setIsCalculating(false);
      return;
    }

    setPressureDisplayValue(pressureCalculateResult.pressure);
    setIsCalculating(false);
  };

  const getTimeUnit = (unit) => {
    if (!unit) {
      return unit;
    }

    return unit.toLowerCase() === "s"
      ? "s"
      : unit.toLowerCase() === "mm/s"
      ? "mm/s"
      : unit;
  };

  // gets full mass unit for display
  const getMassUnit = (unit) => {
    if (!unit) {
      return unit;
    }
    let cs_label = (
      <label>
        mm<sup>2</sup>
      </label>
    );

    return unit.toLowerCase() === "g"
      ? "g"
      : unit === "m"
      ? "ml"
      : unit === "mm^2"
      ? cs_label
      : unit;
  };

  // gets full temperature unit for display
  const getTempUnit = (unit) => {
    if (!unit) {
      return unit;
    }

    return unit.toLowerCase() === "c"
      ? "ºC"
      : unit.toLowerCase() === "f"
      ? "ºF"
      : unit;
  };

  // list of steps to calculate pressure
  let list = (
    <ol type="1">
      <li>Enter product and lot codes </li>
      <li>Select Machine Setup</li>
      <li>Enter desired flow rates</li>
      <li>Enter current temperature near dispenser</li>
      <li>Press "Calculate Pressure"</li>
      <li>Set your air pressure gauge to this value</li>
    </ol>
  );

  let info = (
    <ul>
      <li>
        Use the appropriate 3M<sup>TM</sup> Scotch-Weld<sup>TM</sup> Adhesive
        Dispensing Guide for detailed information on making your purge
        decisions.
      </li>
      <li>
        Elevated temperature will reduce the open time and require more frequent
        purging.
      </li>
      <li>
        Increasing pressure during the purge can help clear material more
        efficiently.
      </li>
      <li>
        Avoid any unnecessary breaks in dispensing as the adhesive will continue
        to cure with downtime. If material cures in the static mixer (or any
        other dispensing component), replace all components.
      </li>
    </ul>
  );

  const headContainer = (
    <MDBRow className="mb-2 mt-2 px-0">
      <MDBRow className="px-0">
        <label>Head Speed</label>
        <MDBCol>
          <MDBInput
            className="mb-2"
            type="number"
            id="time"
            disabled={!props.isProductSelected}
            value={userData.Time}
            onChange={handleChange}
            name="Time"
            required
            min="0.01"
            max="999"
            step="0.01"
          />
        </MDBCol>
        <MDBCol size="1" className="pt-1">
          <span style={{ fontWeight: "bold" }}>
            {getTimeUnit(constants.timeUnits.headSpeed.unit)}
          </span>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-2 mt-2 px-0">
        <label>Bead Cross Sectional Area</label>
        <MDBCol>
          <MDBInput
            type="number"
            id="mass"
            disabled={!props.isProductSelected}
            value={userData.Mass}
            onChange={handleChange}
            name="Mass"
            step="0.0001"
            required
            min="0.0001"
            max="999"
          />
        </MDBCol>
        <MDBCol size="1" className="pt-1">
          <span style={{ fontWeight: "bold" }}>
            {getMassUnit(constants.weightUnits.crossSection.unit)}
          </span>
        </MDBCol>
      </MDBRow>
    </MDBRow>
  );
  const massContainer = (
    <MDBRow className="mb-2 mt-2 px-0">
      <MDBRow className="px-0">
        <label>Time for Dispensing</label>
        <MDBCol>
          <MDBInput
            className="mb-2"
            type="number"
            id="time"
            disabled={!props.isProductSelected}
            value={userData.Time}
            onChange={handleChange}
            name="Time"
            required
            min="0.01"
            max="999"
            step="0.01"
          />
        </MDBCol>
        <MDBCol size="1" className="pt-1">
          <span style={{ fontWeight: "bold" }}>
            {getTimeUnit(constants.timeUnits.seconds.unit)}
          </span>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-2 mt-2 px-0">
        <label>Shot Size (Mass Dispensed)</label>
        <MDBCol>
          <MDBInput
            type="number"
            id="mass"
            disabled={!props.isProductSelected}
            value={userData.Mass}
            onChange={handleChange}
            name="Mass"
            step="0.0001"
            required
            min="0.0001"
            max="999"
          />
        </MDBCol>
        <MDBCol size="1" className="pt-1">
          <span style={{ fontWeight: "bold" }}>
            {getMassUnit(constants.weightUnits.gram.unit)}
          </span>
        </MDBCol>
      </MDBRow>
    </MDBRow>
  );
  const volumnContainer = (
    <MDBRow className="mb-2 mt-2 px-0">
      <MDBRow className="px-0">
        <label>Time for Dispensing</label>
        <MDBCol>
          <MDBInput
            type="number"
            id="time"
            className="mb-2"
            disabled={!props.isProductSelected}
            value={userData.Time}
            onChange={handleChange}
            name="Time"
            required
            min="0.01"
            max="999"
            step="0.01"
          />
        </MDBCol>
        <MDBCol size="1" className="pt-1">
          <span style={{ fontWeight: "bold" }}>
            {getTimeUnit(constants.timeUnits.seconds.unit)}
          </span>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-2 mt-2 px-0">
        <label>Shot Size (Volume Dispensed)</label>
        <MDBCol>
          <MDBInput
            type="number"
            id="mass"
            disabled={!props.isProductSelected}
            value={userData.Mass}
            onChange={handleChange}
            name="Mass"
            step="0.0001"
            required
            min="0.0001"
            max="999"
          />
        </MDBCol>
        <MDBCol size="1" className="pt-1">
          <span style={{ fontWeight: "bold" }}>
            {getMassUnit(constants.weightUnits.milliliter.unit)}
          </span>
        </MDBCol>
      </MDBRow>
    </MDBRow>
  );
  return (
    <>
      <MDBTypography className="text-center component-heading" tag="h4">
        Pressure Calculator
        <MDBTooltip
          tag="span"
          wrapperProps={{ href: "#" }}
          placement="bottom"
          title={list}
        >
          &nbsp;&nbsp;
          <i
            className="fas fa-info-circle fa"
            // style= {{color : '#F11523'}}
            style={{ color: "DodgerBlue" }}
          ></i>
        </MDBTooltip>
      </MDBTypography>

      {!isLoading && machineList.length > 0 ? (
        <MDBContainer className="calculate-pressure-container px-0">
          <form
            onSubmit={handleSubmit}
            className="form-inline square rounded-6 border 
                border-dark px-3 py-2 bg-white form-calc-pressure"
          >
            <label>Machine Setup</label>
            <select
              className="form-control mb-3"
              onChange={handleChange}
              name="Machine"
              disabled={!props.isProductSelected}
              required
            >
              <option value="" disabled selected>
                Select Machine Setup
              </option>
              {machineList.map((item, index) => (
                <option value={index} key={item.PK}>
                  {item.MachineInfo.MachineName}
                </option>
              ))}
            </select>
            <MDBRow className="mb-3">
              <label>Ambient Temperature</label>
              <MDBCol>
                <MDBInput
                  className="mb-2"
                  type="number"
                  id="temperature"
                  step="0.01"
                  disabled={!props.isProductSelected}
                  value={userData.Temperature}
                  onChange={handleChange}
                  name="Temperature"
                  aria-describedby="tempSpan"
                  required
                  min={
                    userData.Machine?.UnitPreferences?.Temperature.toLowerCase() ===
                    "c"
                      ? 15
                      : 60
                  }
                  max={
                    userData.Machine?.UnitPreferences?.Temperature.toLowerCase() ===
                    "c"
                      ? 35
                      : 95
                  }
                />
              </MDBCol>
              <MDBCol size="1" className="pt-2" style={{ fontWeight: "bold" }}>
                <span id="tempSpan">
                  {getTempUnit(userData.Machine?.UnitPreferences?.Temperature)}
                </span>
              </MDBCol>
            </MDBRow>
            <MDBContainer className="square rounded-6 border bg-white">
              <MDBRow>
                <MDBTabs pills>
                  <MDBCol>
                    <MDBTabsItem>
                      <MDBTabsLink
                        color="light"
                        style={{ textAlign: "center" }}
                        className="px-3 mx-1"
                        onClick={() => {
                          handleBasicClick("tab1");
                          setMassUnit("g");
                          setTimeUnit("s");
                        }}
                        active={basicActive === "tab1"}
                      >
                        Mass
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </MDBCol>
                  <MDBCol>
                    <MDBTabsItem>
                      <MDBTabsLink
                        className="px-3 mx-1"
                        style={{ textAlign: "center" }}
                        color="light"
                        onClick={() => {
                          handleBasicClick("tab2");
                          setMassUnit("ml");
                          setTimeUnit("s");
                        }}
                        active={basicActive === "tab2"}
                      >
                        Volume
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </MDBCol>
                  <MDBCol>
                    <MDBTabsItem>
                      <MDBTabsLink
                        color="light"
                        style={{ textAlign: "center" }}
                        className="px-3 mx-1"
                        onClick={() => {
                          handleBasicClick("tab3");
                          setMassUnit("mm^2");
                          setTimeUnit("mm/s");
                        }}
                        active={basicActive === "tab3"}
                      >
                        Bead size
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </MDBCol>
                </MDBTabs>
              </MDBRow>
              <MDBTabsContent className="unitTab">
                <MDBTabsPane show={basicActive === "tab1"}>
                  {massContainer}
                </MDBTabsPane>
                <MDBTabsPane show={basicActive === "tab2"}>
                  {volumnContainer}
                </MDBTabsPane>
                <MDBTabsPane show={basicActive === "tab3"}>
                  {headContainer}
                </MDBTabsPane>
              </MDBTabsContent>
            </MDBContainer>
            <MDBRow className="mb-2 mt-3">
              <MDBCol size="12">
                <MDBBtn type="submit" block disabled={!props.isProductSelected}>
                  Calculate Pressure
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {pressureDisplayValue !== "" && errorMessage === "" ? (
                <MDBCol className="text-center mt-3">
                  <MDBTypography variant="h6" className="air-pressure-heading">
                    Air Pressure <span>({selectedPressureUnit})</span>
                  </MDBTypography>
                  <MDBTypography
                    tag="div"
                    className="display-1 air-pressure mt-0"
                  >
                    {(
                      Math.round(parseFloat(pressureDisplayValue) * 2) / 2
                    ).toFixed(1)}
                  </MDBTypography>
                </MDBCol>
              ) : isCalculating ? (
                <MDBCol className="text-center mt-4">
                  <MDBSpinner className="calculating-spinner">
                    <span className="visually-hidden">Loading...</span>
                  </MDBSpinner>
                </MDBCol>
              ) : (
                ""
              )}

              {errorMessage !== "" ? (
                <MDBCol className="text-center mt-3">
                  <MDBTypography tag="div" className="calc-error-message">
                    {errorMessage}
                  </MDBTypography>
                </MDBCol>
              ) : (
                ""
              )}
            </MDBRow>
          </form>
        </MDBContainer>
      ) : !isLoading && machineList.length === 0 ? (
        <MDBContainer className="square rounded-6 border border-dark p-3 bg-white text-center">
          Please configure a machine on the Machine Setup page above. (Engineer
          role only)
        </MDBContainer>
      ) : (
        <MDBContainer className="square rounded-6 border border-dark p-3 bg-white text-center">
          <MDBSpinner className="calculating-spinner">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </MDBContainer>
      )}
      <br></br>
      <Alert
        severity="info"
        sx={{ color: "text.primary", fontSize: 14, fontWeight: "medium" }}
      >
        Regular purging of your dispenser may be necessary to maintain
        consistent flow.
        <MDBTypography
          className="mt-3 mb-0"
          style={{
            fontSize: "14px",
            color: "#1266f1",
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
          onClick={() => setLearnMore(true)}
        >
          Click here to learn more
        </MDBTypography>
      </Alert>
      <PurgingSuggestion trigger={learnMore} setTrigger={setLearnMore}>
        <p>
          Purging regularly is a necessary part of the time-pressure dispensing
          process as the adhesive will start curing as soon as you begin
          dispensing. Dispensing at low pressures and flow rates are not
          suggested for the most consistent dispense results. The following
          rules of thumb can help keep the adhesive in the dispenser fresher and
          the flow rate more consistent.
        </p>
        {info}
        <p>
          Please make sure to abide by equipment limitations and not dispense
          outside normal operating conditions.
        </p>
        <p>
          For additional guidance, view the{" "}
          <a href={faqPDF} rel="noreferrer" target="_blank">
            {" "}
            FAQs
          </a>{" "}
          or contact us at
          <a href="mailto: 3MDigitalDispensingAssistant@mmm.com">
            {" "}
            3MDigitalDispensingAssistant@mmm.com
          </a>
          .
        </p>
      </PurgingSuggestion>
    </>
  );
}
