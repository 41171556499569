import {
    MDBContainer,
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBSpinner
} from "mdb-react-ui-kit";
import React, { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import ProductService from "../../services/product";

import './OCRScanner.css'

export default function OCRScanner(props) {
    const videoConstraints = {
        facingMode: "environment",
    }
    const webcamRef = useRef(null)
    const imageRef = useRef(null)
    const [isCaptured, setIsCaptured] = useState(false)
    const [imgSrc, setImgSrc] = useState('')

    useEffect(() => {
        if (isCaptured) {
            let img = new Image()
            img.src = imgSrc
            img.onload = function () {
                const image = imageRef.current
                const ctx = image.getContext('2d')
                ctx.drawImage(img, 0, 0, videoConstraints.width, videoConstraints.height)
            }
        }

    }, [isCaptured])

    const capture = useCallback(() => {
        let imageSrc = webcamRef.current.getScreenshot()
        setImgSrc(imageSrc)
        setIsCaptured(true)
        props.setIsLoadingOCR(true)
        ProductService.scanLots(b64toBlob(imageSrc))
            .then((data) => {
                props.setScanResponse(data)
            }).catch((err) => {
                let response = {
                    Message: err
                }
                props.setScanResponse(response)
            }).finally(() => {
                props.setIsLoadingOCR(false)
                props.setShowOCR(false)
            })
    }, [webcamRef]
    )

    return (
        <>
            <MDBContainer className="square rounded-6 border border-dark mt-4 bg-white">
                <MDBRow>
                    <MDBCol>
                        <div className="embed-responsive embed-responsive-16by9">
                            {
                                !isCaptured ?
                                    <Webcam
                                        audio={false}
                                        forceScreenshotSourceSize="true"
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                        className="embed-responsive-item"
                                        ref={webcamRef}
                                        style={{
                                            position: "absolute",
                                            textAlign: "center",
                                            zindex: 8,
                                            right: 0,
                                            width: "100%",
                                            objectFit: "fill",
                                        }}
                                    /> :
                                    <canvas ref={imageRef} className="mt-4 embed-responsive-item"></canvas>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBBtn className="container mb-3 mt-3" onClick={capture} disabled={props.isLoadingOCR}>Scan</MDBBtn>
            {
                props.isLoadingOCR ?
                    <MDBContainer className="container d-flex justify-content-center flex-nowrap">
                        <MDBSpinner className="calculating-spinner">
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                    </MDBContainer> : ''

            }
        </>

    )
}

function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1])
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/jpeg' })
}