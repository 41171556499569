import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBRow,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCol,
  MDBRadio,
  MDBSpinner,
  MDBTooltip,
} from "mdb-react-ui-kit";
import MachineService from "../../services/machine";
import SelectOrTextInput from "../SelectOrTextInput";
import { CheckPermission } from "../CheckPermission";
import * as constants from "../../services/constants";
import "./AddMachine.css";

export default function AddMachine(props) {
  // unit preferences
  const [unitPreferences, setUnitPreferences] = useState({
    Pressure: constants.pressureUnits.psi.unit,
    Temperature: constants.temperatureUnits.celsius.unit,
    Weight: constants.weightUnits.gram.unit,
    Time: constants.timeUnits.seconds.unit,
  });

  // initialize machine setup dropdowns
  const [dispensers, setDispensers] = useState([]);
  const [staticMixers, setStaticMixers] = useState([]);
  const [adapters, setAdapters] = useState([]);
  const [valves, setValves] = useState([]);
  const [tips, setTips] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const navigate = useNavigate();

  // get data for dropdowns
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      MachineService.getDispensers(),
      MachineService.getStaticMixers(),
      MachineService.getAdapters(),
      MachineService.getValves(),
      MachineService.getTips(),
    ])
      .then(([dispensers, staticMixers, adapters, valves, tips]) => {
        setDispensers(dispensers);
        setStaticMixers(staticMixers);
        setAdapters(adapters);
        setValves(valves);
        setTips(tips);
        if (props.isEdit && !props.isLoading) {
          setMachineData(props.machineDetails.MachineInfo);
          setUnitPreferences(props.machineDetails.UnitPreferences);
        }
      })
      .catch((err) => {
        if (err.message === "Unauthorized") {
          navigate("/unauthorized");
        }
        setErrorMessage(err);
      })
      .finally(() => setIsLoading(false));
  }, [props, navigate]);

  // initialize machine setup form
  const [machineData, setMachineData] = useState({
    MachineName: "",
    Dispenser: "",
    OtherDispenser: "",
    StaticMixer: "",
    OtherStaticMixer: "",
    Adapter: "",
    OtherAdapter: "",
    Valve: "",
    OtherValve: "",
    Tip: "",
    OtherTip: "",
    TipDiscription: "",
    MinPressure: 20,
    // MaxPressure: ,
    Notes: "",
  });

  const handleDeactivateOnClick = () => {
    setShowDeactivateModal(true);
  };
  const handleCancel = () => {
    setShowDeactivateModal(false);
  };
  // handles form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMachineData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetSelectInput = (type) => {
    setMachineData((prevState) => ({
      ...prevState,
      [type]: "",
      [`Other${type}`]: "",
    }));
  };

  // handles unit preference changes
  const handleUnitPreferenceChange = (e) => {
    const { name, value } = e.target;
    // check if this is the cross sectional area option
    if (name === "Weight") {
      if (value === constants.weightUnits.crossSection.unit) {
        setUnitPreferences((prevState) => ({
          ...prevState,
          [name]: value,
          Time: constants.timeUnits.headSpeed.unit,
        }));
      } else {
        setUnitPreferences((prevState) => ({
          ...prevState,
          [name]: value,
          Time: constants.timeUnits.seconds.unit,
        }));
      }
    } else {
      setUnitPreferences((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // handles machine setup form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    if (machineData.MinPressure > machineData.MaxPressure) {
      setErrorMessage(
        "Maximum pressure must be greater than or equal to minimum pressure."
      );
      setIsLoading(false);
      return;
    }

    let machine = {
      MachineInfo: machineData,
      UnitPreferences: unitPreferences,
    };

    MachineService.saveMachineDetails(machine)
      .then((response) => {
        if (response.detail) {
          setErrorMessage(response.detail);
          return;
        }

        navigate("/machine-details", {
          state: {
            machineId: response.PK,
          },
        });
      })
      .catch((err) => {
        if (err.message === "Unauthorized") {
          navigate("/unauthorized");
        }
        if (err.message === "Duplicate Machine Name") {
          setIsLoading(false);
          window.alert(
            "You already have a machine with this name. Please rename."
          );
          setMachineData((prevState) => ({
            ...prevState,
            MachineName: "",
          }));
        } else {
          setErrorMessage(err);
        }
      })
      .finally(() => setIsLoading(false));
  };

  // handles machine edit
  const handleEdit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    if (machineData.MinPressure > machineData.MaxPressure) {
      setErrorMessage(
        "Maximum pressure must be greater than or equal to minimum pressure."
      );
      return;
    }

    let machine = {
      PK: props.machineDetails.PK,
      SK: props.machineDetails.SK,
      MachineInfo: machineData,
      UnitPreferences: unitPreferences,
    };

    MachineService.saveMachineDetails(machine)
      .then((response) => {
        if (response.detail) {
          setErrorMessage(response.detail);
          return;
        }
        props.setMachineDetails(machine);
        props.toggleIsEdit();
      })
      .catch((err) => {
        if (err.message === "Duplicate Machine Name") {
          setIsLoading(false);
          window.alert(
            "You already have a machine with this name. Please rename."
          );
          setMachineData((prevState) => ({
            ...prevState,
            MachineName: "",
          }));
        } else {
          setErrorMessage(err);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const deactivateMachine = (e) => {
    let machine = {
      PK: props.machineDetails.PK,
      SK: props.machineDetails.SK,
      MachineInfo: machineData,
      UnitPreferences: unitPreferences,
      IsActive: false,
    };

    MachineService.saveMachineDetails(machine)
      .then((response) => {
        if (response.detail) {
          setErrorMessage(response.detail);
          return;
        }
        props.setMachineDetails(machine);
        props.toggleIsEdit();
        navigate("/machine-setup");
      })
      .catch((err) => {
        setErrorMessage(err);
      })
      .finally(() => setIsLoading(false));
  };
  let cs_label = (
    <label>
      cross sectional area (mm<sup>2</sup> and mm/s)
    </label>
  );

  return (
    <CheckPermission permission={constants.scopes.canViewMachineSetup}>
      <MDBContainer className="add-machine-container px-0">
        <MDBRow>
          {props.isEdit ? (
            <MDBTypography className="text-center component-heading" tag="h4">
              Edit Machine Setup
            </MDBTypography>
          ) : (
            <MDBTypography className="text-center component-heading" tag="h4">
              Add Machine Setup
              <MDBTooltip
                tag="span"
                wrapperClass="d-inline-block cursor-pointer"
                placement="top"
                title={
                  <MDBContainer className="ocr-tooltip">
                    Please create multiple Machine Setups when using different
                    components (e.g. the same dispenser with two different tips
                    is two different Machine Setups).
                  </MDBContainer>
                }
              >
                &nbsp;&nbsp;
                <i
                  className="fas fa-info-circle fa"
                  style={{ color: "DodgerBlue" }}
                ></i>
              </MDBTooltip>
            </MDBTypography>
          )}
        </MDBRow>

        <form
          onSubmit={props.isEdit ? handleEdit : handleSubmit}
          className="form-inline"
          autoComplete="off"
        >
          {isLoading ? (
            <MDBContainer className="rounded-6 border border-dark px-3 py-2 bg-white text-center">
              <MDBSpinner className="calculating-spinner">
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            </MDBContainer>
          ) : (
            <div className="square rounded-6 border border-dark px-3 py-2 bg-white">
              <MDBRow>
                <MDBCol>
                  <label className="d-flex justify-content-start">
                    Machine Setup Name
                  </label>
                </MDBCol>
                <MDBCol>
                  <label className="d-flex justify-content-end unique-name-text">
                    * unique name
                  </label>
                </MDBCol>
              </MDBRow>

              <MDBInput
                className="mb-2"
                type="input"
                id="machine-name"
                placeholder="Unique display name"
                value={machineData.MachineName}
                onChange={handleChange}
                name="MachineName"
                required
              />

              <SelectOrTextInput
                onChange={handleChange}
                className="selectOrTextInput"
                value={machineData.Dispenser}
                name="Dispenser"
                text="Dispenser"
                itemList={dispensers}
                other={machineData.OtherDispenser}
                resetSelectInput={resetSelectInput}
                disabled={false}
              />

              <SelectOrTextInput
                onChange={handleChange}
                value={machineData.StaticMixer}
                className="selectOrTextInput"
                name="StaticMixer"
                text="Static Mixer"
                itemList={staticMixers}
                other={machineData.OtherStaticMixer}
                resetSelectInput={resetSelectInput}
                disabled={false}
              />

              <SelectOrTextInput
                onChange={handleChange}
                className="selectOrTextInput"
                value={machineData.Adapter}
                name="Adapter"
                text="Adapter"
                itemList={adapters}
                other={machineData.OtherAdapter}
                resetSelectInput={resetSelectInput}
                disabled={false}
              />

              <SelectOrTextInput
                onChange={handleChange}
                className="selectOrTextInput"
                value={machineData.Valve}
                name="Valve"
                text="Valve"
                itemList={valves}
                other={machineData.OtherValve}
                resetSelectInput={resetSelectInput}
                disabled={false}
              />

              <SelectOrTextInput
                onChange={handleChange}
                className="selectOrTextInput"
                value={machineData.Tip}
                name="Tip"
                text="Tip"
                itemList={tips}
                other={machineData.OtherTip}
                resetSelectInput={resetSelectInput}
                disabled={false}
              />
              <MDBRow>
                <MDBCol className="col-md-10">
                  <MDBRow>
                    <MDBCol>
                      <label>Minimum Pressure</label>
                    </MDBCol>
                    <MDBCol>
                      <label>
                        Maximum Pressure &nbsp;
                        <MDBTooltip
                          tag="span"
                          wrapperClass="d-inline-block cursor-pointer"
                          placement="top"
                          title={
                            <MDBContainer className="ocr-tooltip">
                              Please check your machine specifications for
                              guidance on maximum air pressure allowable.
                              <br></br>
                              <br></br>
                              This typically ranges from 60 to 85 psi (414 to
                              586 kPa).
                            </MDBContainer>
                          }
                        >
                          <i
                            className="fas fa-info-circle fa-lg"
                            style={{ color: "DodgerBlue" }}
                          ></i>
                        </MDBTooltip>
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {props.isEdit ? (
                    <MDBRow className="mb-2">
                      <MDBCol>
                        <MDBInput
                          id="MinPressure"
                          name="MinPressure"
                          type="number"
                          value={machineData.MinPressure}
                          disabled
                        />
                      </MDBCol>
                      <MDBCol className="mx-0 px-0 my-auto">
                        <label>{unitPreferences.Pressure}</label>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          id="MaxPressure"
                          name="MaxPressure"
                          type="number"
                          value={machineData.MaxPressure}
                          disabled
                        />
                      </MDBCol>
                      <MDBCol className="mx-0 px-0 my-auto">
                        <label>{unitPreferences.Pressure}</label>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    <MDBRow className="mb-2">
                      <MDBCol>
                        <MDBInput
                          id="MinPressure"
                          name="MinPressure"
                          onChange={handleChange}
                          type="number"
                          value={machineData.MinPressure}
                          required
                          min={
                            unitPreferences.Pressure.toLowerCase() ===
                            constants.pressureUnits.kpa.unit
                              ? 103
                              : 15
                          }
                          max={
                            unitPreferences.Pressure.toLowerCase() ===
                            constants.pressureUnits.kpa.unit
                              ? 586
                              : 85
                          }
                          disabled={props.isEdit}
                        />
                      </MDBCol>
                      <MDBCol className="mx-0 px-0 my-auto">
                        <label>{unitPreferences.Pressure}</label>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          id="MaxPressure"
                          name="MaxPressure"
                          onChange={handleChange}
                          type="number"
                          // value={machineData.MaxPressure}
                          required
                          min={
                            unitPreferences.Pressure.toLowerCase() ===
                            constants.pressureUnits.kpa.unit
                              ? 103
                              : 15
                          }
                          max={
                            unitPreferences.Pressure.toLowerCase() ===
                            constants.pressureUnits.kpa.unit
                              ? 586
                              : 85
                          }
                          disabled={props.isEdit}
                        />
                      </MDBCol>
                      <MDBCol className="mx-0 px-0 my-auto">
                        <label>{unitPreferences.Pressure}</label>
                      </MDBCol>
                    </MDBRow>
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <label>Notes</label>
              </MDBRow>
              <MDBInput
                className="mb-2"
                type="input"
                id="Notes"
                name="Notes"
                placeholder="Optional"
                value={machineData.Notes}
                onChange={handleChange}
              />

              {props.isEdit ? (
                <MDBBtn
                  type="submit"
                  className="mt-3"
                  disabled={isLoading}
                  block
                >
                  Update Machine Setup Details
                </MDBBtn>
              ) : (
                ""
              )}
            </div>
          )}
          {props.isEdit ? (
            <></>
          ) : (
            <>
              <MDBTypography
                className="text-center component-heading mt-1"
                tag="h4"
              >
                Machine Setup Units
              </MDBTypography>
              <div className="mt-1 px-1 py-2 square rounded-6 border border-dark bg-white">
                <MDBRow className="ms-2">
                  <MDBRow>
                    <label>Unit of Temperature:</label>
                  </MDBRow>
                  <MDBRow className="border-bottom">
                    <MDBCol>
                      <MDBRadio
                        name="Temperature"
                        id="temperatureF"
                        label={constants.temperatureUnits.fahrenheit.name}
                        checked={
                          unitPreferences.Temperature.toLowerCase() ===
                          constants.temperatureUnits.fahrenheit.unit
                        }
                        value={constants.temperatureUnits.fahrenheit.unit}
                        onChange={handleUnitPreferenceChange}
                        disabled={props.isEdit}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBRadio
                        name="Temperature"
                        id="temperatureC"
                        label={constants.temperatureUnits.celsius.name}
                        checked={
                          unitPreferences.Temperature.toLowerCase() ===
                          constants.temperatureUnits.celsius.unit
                        }
                        value={constants.temperatureUnits.celsius.unit}
                        onChange={handleUnitPreferenceChange}
                        disabled={props.isEdit}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-2">
                    <label>
                      Unit of Flow Rate:
                      <MDBTooltip
                        tag="span"
                        wrapperClass="d-inline-block cursor-pointer"
                        placement="top"
                        title={
                          <MDBContainer className="ocr-tooltip">
                            Mass (g) and Volume (ml) units are inputted with a
                            dispense time. Often useful for potting applications
                            <br></br>
                            <br></br>
                            Cross sectional area units lets you specify the bead
                            size (area) and the line/head speed. Often used with
                            path dispensing.
                          </MDBContainer>
                        }
                      >
                        &nbsp;&nbsp;
                        <i
                          className="fas fa-info-circle fa-lg"
                          style={{ color: "DodgerBlue" }}
                        ></i>
                      </MDBTooltip>
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBRadio
                        name="Weight"
                        id="weightG"
                        label={constants.weightUnits.gram.name}
                        checked={
                          unitPreferences.Weight.toLowerCase() ===
                          constants.weightUnits.gram.unit
                        }
                        value={constants.weightUnits.gram.unit}
                        onChange={handleUnitPreferenceChange}
                        disabled={props.isEdit}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBRadio
                        name="Weight"
                        id="weightM"
                        label={constants.weightUnits.milliliter.name}
                        checked={
                          unitPreferences.Weight.toLowerCase() ===
                          constants.weightUnits.milliliter.unit
                        }
                        value={constants.weightUnits.milliliter.unit}
                        onChange={handleUnitPreferenceChange}
                        disabled={props.isEdit}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="border-bottom">
                    <MDBCol>
                      <MDBRadio
                        name="Weight"
                        id="weightCS"
                        label={cs_label}
                        checked={
                          unitPreferences.Weight.toLowerCase() ===
                          constants.weightUnits.crossSection.unit
                        }
                        value={constants.weightUnits.crossSection.unit}
                        onChange={handleUnitPreferenceChange}
                        disabled={props.isEdit}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-2">
                    <label>Unit of Pressure:</label>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBRadio
                        name="Pressure"
                        id="pressureP"
                        label={constants.pressureUnits.psi.name}
                        checked={
                          unitPreferences.Pressure.toLowerCase() ===
                          constants.pressureUnits.psi.unit
                        }
                        value={constants.pressureUnits.psi.unit}
                        onChange={handleUnitPreferenceChange}
                        disabled={props.isEdit}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBRadio
                        name="Pressure"
                        id="pressureK"
                        label={constants.pressureUnits.kpa.name}
                        checked={
                          unitPreferences.Pressure.toLowerCase() ===
                          constants.pressureUnits.kpa.unit
                        }
                        value={constants.pressureUnits.kpa.unit}
                        onChange={handleUnitPreferenceChange}
                        disabled={props.isEdit}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBRow>
              </div>
            </>
          )}

          {props.isEdit ? (
            <MDBRow className="my-2 py-1">
              <label>Delete Machine Setup:</label>
              <MDBRow className="mx-0 px-4 py-2 my-auto">
                <MDBBtn
                  type="button"
                  block
                  onClick={handleDeactivateOnClick}
                  style={{ width: "50%" }}
                  className="deactivate-button"
                >
                  Delete
                </MDBBtn>
              </MDBRow>
            </MDBRow>
          ) : (
            <MDBBtn
              type="submit"
              className="mt-3 mb-2"
              disabled={isLoading}
              block
            >
              Continue to Calibration
            </MDBBtn>
          )}
          {errorMessage !== "" ? (
            <MDBTypography note className="mt-3" noteColor="danger">
              <strong>Error:</strong> {errorMessage}
            </MDBTypography>
          ) : (
            ""
          )}
        </form>
        <MDBModal show={showDeactivateModal} staticBackdrop>
          <MDBModalDialog>
            <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
              <MDBModalHeader>
                <b>Message</b>
              </MDBModalHeader>
              <MDBModalBody>
                Press Delete to confirm the deletion of this machine setup. This
                action cannot be undone.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="cancel-button"
                  color="primary"
                  size="sm"
                  onClick={handleCancel}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn
                  className="delete-button"
                  size="sm"
                  onClick={deactivateMachine}
                >
                  Delete
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    </CheckPermission>
  );
}
