import AccountService from "./account"
import store from '../store'
import { reset } from "./userDataSlice"

const ProductService = (function () {

  // gets the list of products by tenant from the server
  const getAllProducts = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/product/all`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });

    if (response.ok) {
      return await response.json()
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // gets the list of products by tenant from the server
  const getProductsByTenant = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/product/`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });

    if (response.ok) {
      return await response.json()
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // get company name from tenant id
  const getCompany = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/tenant/`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });

    if (response.ok) {
      return await response.json()
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // finds product for pressure calculator
  const findProduct = async function (productId, lotId, part) {
    const session = await getCurrentSession()
    const response = 
    await fetch(`${getAPIServerURL()}/api/product/${encodeURIComponent(productId)}/lot/${encodeURIComponent(lotId)}/part/${encodeURIComponent(part)}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.accessToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });

    if (response.ok) {
      return await response.json()
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // gets product details data
  const getProductDetails = async function (productId) {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/product/${encodeURIComponent(productId)}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.accessToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });

    if (response.ok) {
      return await response.json()
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // scan lots using image
  const scanLots = async function (img) {
    const session = await getCurrentSession()
    const formData = new FormData();
    formData.append("file", img);
    const result = await fetch(`${getAPIServerURL()}/api/product/scan`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.accessToken.jwtToken,
      }),
      body: formData
    });
    return await result.json();
  }

  const getCurrentSession = async () => {
    const session = await AccountService.getSession()
      .then(session => session)
      .catch(_ => {
        AccountService.logout()
        store.dispatch(reset())
      })
    return session
  }

  // gets API server URL
  const getAPIServerURL = () => !process.env.REACT_APP_API_URL ? '' : process.env.REACT_APP_API_URL;

  return {
    getAllProducts: getAllProducts,
    getProductsByTenant: getProductsByTenant,
    findProduct: findProduct,
    getCompany: getCompany,
    getProductDetails: getProductDetails,
    scanLots: scanLots
  }

})()

export default ProductService