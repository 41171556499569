import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

import "./Layout.css";

export default function Layout(props) {
  return (
    
    <div className="page-container">
      <NavBar
        isLoggedIn={props.isLoggedIn}
        setIsLoggedIn={props.setIsLoggedIn}
      />
      <div className="content-wrap">
        <main className="flex flex-col h-screen justify-between">
          {props.children}
        </main>
        </div>
      <Footer/>
    </div>
  );
}
