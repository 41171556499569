import React from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';

import pic_1 from "../../assets/images/carousel2/Picture1.png";
import pic_2 from "../../assets/images/carousel2/Picture2.png";
import pic_3 from "../../assets/images/carousel2/Picture3.png";
import pic_4 from "../../assets/images/carousel2/Picture4.png";
import "./Carousel2.css"

export default function Carousel2() {
  

  return (

    <MDBContainer className="square rounded-6  px-3 py-2 bg-white">
      <MDBRow>
        <MDBCol md="0"></MDBCol>
        <MDBCol md="12">
    
          <MDBCarousel showControls  dark fade interval={100000000000} >
            <MDBCarouselInner>
              <MDBCarouselItem 
                className='d-block h-100 text-center active'
                itemID={1}
                >
                <img src={pic_1} height="100%" width="100%"/>
              </MDBCarouselItem>

              <MDBCarouselItem
                className='d-block h-100 text-center'
                >
                <img src={pic_2} height="100%" width="100%"/>
              </MDBCarouselItem>
      
              <MDBCarouselItem
                className='d-block h-100 text-center'
                itemID={3}
                alt='...'
              >
                <img src={pic_3} height="100%" width="100%"/>
              </MDBCarouselItem>

              <MDBCarouselItem
                className='d-block h-100 text-center'
                itemID={4}
                alt='...'
              >
                <img src={pic_4} height="100%" width="100%"/>
              </MDBCarouselItem> 
            </MDBCarouselInner>
          </MDBCarousel>
    
        </MDBCol>

      <MDBCol md="0"></MDBCol>
      </MDBRow>
    </MDBContainer>

  );
}