import React, { createContext } from "react";
import AccountService from "../../services/account";

const AccountContext = createContext();

const Account = props => {
  const getSession = AccountService.getSession
  const authenticate = AccountService.authenticate
  const logout = AccountService.logout

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
