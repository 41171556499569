import AccountService from "./account"
import store from '../store'
import { reset } from "./userDataSlice"

const MachineService = (function () {

  // gets the list of machines from the server
  const getMachineList = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })

    if (response.ok) {
      let list = await response.json()
      list = list.sort((a,b) => (a.MachineInfo.MachineName.toLowerCase() > b.MachineInfo.MachineName.toLowerCase()) ? 1 : -1)
      return list
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // gets the list of dispensers from the server
  const getDispensers = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/dispensers`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })

    if (response.ok) {
      let list = await response.json()
      list = list.sort()
      list.push(list.splice(list.indexOf('Other'), 1).pop());
      return list
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // gets the list of static mixers from the server
  const getStaticMixers = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/static-mixers`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })

    if (response.ok) {
      let list = await response.json()
      list = list.sort()
      list.push(list.splice(list.indexOf('Other'), 1).pop());
      return list
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // gets the list of adapters from the server
  const getAdapters = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/adapters`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })

    if (response.ok) {
      let list = await response.json()
      list = list.sort()
      list.push(list.splice(list.indexOf('None'), 1).pop());
      list.push(list.splice(list.indexOf('Other'), 1).pop());
      return list
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // gets the list of valves from the server
  const getValves = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/valves`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })

    if (response.ok) {
      let list = await response.json()
      list = list.sort()
      list.push(list.splice(list.indexOf('None'), 1).pop());
      list.push(list.splice(list.indexOf('Other'), 1).pop());
      return list
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }

  }

  // gets the list of tips from the server
  const getTips = async function () {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/tips`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })

    if (response.ok) {
      let list = await response.json()
      list = list.sort()
      list.push(list.splice(list.indexOf('None'), 1).pop());
      list.push(list.splice(list.indexOf('Other'), 1).pop());
      return list
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // get machine details from the server
  const getMachineDetails = async function (machineId) {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/${encodeURIComponent(machineId)}/`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })

    if (response.ok) {
      return await response.json()
    }

    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  // save machine details
  const saveMachineDetails = async function (item) {
    const session = await getCurrentSession()
    const response = await fetch(`${getAPIServerURL()}/api/machine/`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    })

    if (response.ok) {
      return await response.json()
    }
    if (response.status === 404){
      throw new Error('Duplicate Machine Name')
    }
    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }

  const getCurrentSession = async () => {
    var session = await AccountService.getSession()
    .then(session => {
      return session
    }).catch(_ => {
      AccountService.logout()
      store.dispatch(reset())
    })
    return session
  }

  // gets API server URL
  const getAPIServerURL = () => !process.env.REACT_APP_API_URL ? '' : process.env.REACT_APP_API_URL

  return {
    saveMachineDetails: saveMachineDetails,
    getMachineDetails: getMachineDetails,
    getTips: getTips,
    getValves: getValves,
    getAdapters: getAdapters,
    getStaticMixers: getStaticMixers,
    getDispensers: getDispensers,
    getMachineList: getMachineList
  }

})()

export default MachineService