import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Storyboard from "../../components/Storyboard";
import Carousel1 from "../../components/Carousel1";
import Carousel2 from "../../components/Carousel2";
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBTypography,
    MDBIcon,
    MDBSpinner
} from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { CheckPermission } from "../../components/CheckPermission"
import * as constants from '../../services/constants'

export default function UserProfile() {
    
    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
      };

    const [basicActive, setBasicActive] = useState("tab1");

    const example1 = (
        <MDBRow className="mb-2 mt-2 px-0">
        <MDBRow className="px-0">
            <label>Time for Dispensing</label>
            <MDBCol>
            <MDBInput
                className="mb-2"
                type="number"
                id="time"
                // value={userData.Time}
                // onChange={handleChange}
                name="Time"
                required
                min="0.01"
                max="999"
                step="0.01"
            />
            </MDBCol>
        </MDBRow>
        
        </MDBRow>
    );

    const { userData } = useSelector(state => state)
    const navigate = useNavigate()

    useEffect(() => {
        if (!userData || !userData.isLoggedIn) {
            navigate('/')
            return
        }
    }, [userData, navigate])


    return (
        <>
        {
        userData.isLoggedIn ?
            <CheckPermission permission={constants.scopes.canViewStoryboard} isComponent={true}>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol className="mt-3 mb-2" lg="12" md="12">
                            <Storyboard></Storyboard>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mx-2" >
                        <MDBCol className="mt-1 mb-2" lg="12"  md="12">
                            <MDBContainer className="square rounded-6 px-3 py-2 border border-dark bg-white">
                            <MDBRow>
                                <MDBContainer >
                                    <MDBRow className="text-center mx-0">
                                        <MDBTabs pills >
                                        <MDBCol md="6">
                                            <MDBTabsItem>
                                            <MDBTabsLink
                                                color="light"
                                                style={{ textAlign: "center" }}
                                                className="px-3 mx-1"
                                                onClick={() => {
                                                handleBasicClick("tab1");
                                                }}
                                                active={basicActive === "tab1"}
                                            >
                                                Temperature Effect Case Study
                                            </MDBTabsLink>
                                            </MDBTabsItem>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBTabsItem>
                                            <MDBTabsLink
                                                className="px-3 mx-1"
                                                style={{ textAlign: "center" }}
                                                color="light"
                                                onClick={() => {
                                                handleBasicClick("tab2");
                                                }}
                                                active={basicActive === "tab2"}
                                            >
                                                Pressure Effect Case Study
                                            </MDBTabsLink>
                                            </MDBTabsItem>
                                        </MDBCol>
                                        </MDBTabs>
                                    </MDBRow>
                                    <MDBTabsContent className="unitTab">
                                        <MDBTabsPane show={basicActive === "tab1"}>
                                        <Carousel1></Carousel1> 
                                        </MDBTabsPane>
                                        <MDBTabsPane show={basicActive === "tab2"}>
                                        <Carousel2></Carousel2> 
                                        </MDBTabsPane>
                                    </MDBTabsContent>
                                    </MDBContainer>
                            </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </CheckPermission> : ''
        }
    </>
)
}
