import React from "react";
import "./PurgingSuggestion.css";
import { MDBIcon } from "mdb-react-ui-kit";
function PurgingSuggestion(props) {
  return props.trigger ? (
    <div className="purgingSuggestion">
      <div className="purgingSuggestion-inner">
        <MDBIcon
          className="close-btn"
          role="button"
          fas
          icon="times"
          size="2x"
          onClick={() => props.setTrigger(false)}
        />
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
export default PurgingSuggestion;
