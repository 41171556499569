import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flags } from "react-feature-flags";
import img from "../../../assets/images/3M_Logo.svg";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBTypography,
} from "mdb-react-ui-kit";
import { AccountContext } from "../../Accounts";
import "./NavBar.css";
import * as constants from "../../../services/constants";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../../services/userDataSlice";
import { CheckPermission } from "../../CheckPermission";

export default function NavBar() {
  const [showBasic, setShowBasic] = useState(false);
  const { logout } = useContext(AccountContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state);
  const logoutUser = (event) => {
    event.preventDefault();
    logout();
    dispatch(reset());
    navigate("/");
  };

  return (
    <MDBNavbar light expand="lg">
      <MDBNavbarBrand href="/" className="brand-margin">
        <img src={img} height="28" alt="3M Logo" />
        <div className="vr mx-3"></div>
        <MDBTypography tag="h4" className="text-wrap">
          3M™ Scotch-Weld™ Digital Dispensing Assistant
        </MDBTypography>
      </MDBNavbarBrand>
      {userData.isLoggedIn ? (
        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
      ) : (
        ""
      )}
      <MDBCollapse navbar show={showBasic}>
        <MDBNavbarNav>
          {userData.isLoggedIn ? (
            <>
              <Flags authorizedFlags={["machine-functionality"]}>
                <CheckPermission
                  permission={constants.scopes.canViewMachineSetup}
                >
                  <MDBNavbarItem className="clickable">
                    <MDBNavbarLink
                      href="/machine-setup"
                      active={
                        location.pathname === "/machine-setup" ||
                        location.pathname === "/machine-details"
                      }
                    >
                      Machine Setup
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                </CheckPermission>
              </Flags>
              <CheckPermission
                permission={constants.scopes.canViewPressureCalculator}
              >
                <MDBNavbarItem className="clickable">
                  <MDBNavbarLink
                    href="/pressure-calculator"
                    active={location.pathname === "/pressure-calculator"}
                  >
                    Pressure Calculator
                  </MDBNavbarLink>
                </MDBNavbarItem>
              </CheckPermission>
              <CheckPermission
                permission={constants.scopes.canViewStoryboard}
              >
                <MDBNavbarItem className="clickable">
                  <MDBNavbarLink
                    href="/storyboard"
                    active={location.pathname === "/storyboard"}
                  >
                    Case Studies
                  </MDBNavbarLink>
                </MDBNavbarItem>
              </CheckPermission>
              <MDBNavbarItem className="clickable">
                <MDBNavbarLink
                  href="/user-profile"
                  active={location.pathname === "/user-profile"}
                >
                  {userData.email}
                  <MDBIcon
                    className="ms-2 clickable"
                    size="lg"
                    fas
                    icon="sign-out-alt"
                    onClick={logoutUser}
                  />
                </MDBNavbarLink>
              </MDBNavbarItem>
            </>
          ) : (
            ""
          )}
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
}
