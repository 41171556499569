import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBContainer,
    MDBSpinner,
    MDBTypography
} from 'mdb-react-ui-kit';
import ChangePassword from "../ChangePassword"
import { AccountContext } from "../Accounts"
import * as constants from "../../services/constants"
import termsPDF from "../../TermsOfUse.pdf";
import { save } from "../../services/userDataSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";

export default function LoginForm(props) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isFirstLogin, setIsFirstLogin] = useState(false)
    const [user, setUser] = useState({})
    const [userAttr, setUserAttr] = useState({})
    const { authenticate } = useContext(AccountContext)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { userData } = useSelector(state => state)


    const passwordChangeHandler = (e) =>{
        setIsFirstLogin(true);
    }

    useEffect(() => {
        if (userData && userData.isLoggedIn) {
            if (userData.role === constants.roles.operator) {
                navigate('/pressure-calculator')
            } else {
                navigate('/machine-setup')
            }
        }
    }, [navigate, userData])

    const onSubmit = event => {
        event.preventDefault()
        setErrorMessage('')
        setIsLoading(true)
        authenticate(email, password)
            .then(session => {
                if (session.isFirstLogin) {
                    setIsFirstLogin(session.isFirstLogin)
                    setUser(session.user)
                    setUserAttr(session.userAttr)
                    setIsLoading(false)
                    return
                }
                if (session.idToken.payload[constants.cognitoAttributes.userRole] === undefined) {
                    throw new Error("Your user role is undefined. Please try again later. If problem persists, please contact the administrator.")
                }
                if (session.idToken.payload[constants.cognitoAttributes.tenantId] === undefined) {
                    throw new Error("Your tenant (Company) is undefined. Please try again later. If problem persists, please contact the administrator.")
                }

                let userRole = session.idToken.payload[constants.cognitoAttributes.userRole].toUpperCase()

                dispatch(save({
                    role: userRole,
                    email: session.idToken.payload[constants.cognitoAttributes.email],
                    tenantId: session.idToken.payload[constants.cognitoAttributes.tenantId],
                    isLoggedIn: true
                }));

                if (userRole === constants.roles.operator) {
                    navigate('/pressure-calculator')
                } else {
                    navigate('/machine-setup')
                }
            })
            .catch(err => {
                setIsLoading(false)
                setErrorMessage(err.message)
            })
    }
    return (
        <>
            {
                isLoading ?
                    <MDBContainer breakpoint='sm' className='login-container mt-5'>
                        <MDBRow>
                            <MDBCol md='6'></MDBCol>
                            <MDBCol>
                                <MDBSpinner className="calculating-spinner">
                                    <span className='visually-hidden'>Loading...</span>
                                </MDBSpinner>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    :
                    !isFirstLogin ?
                        <MDBContainer breakpoint='md' className='login-container mt-4'>
                            <MDBRow>
                                <MDBCol md='3' xs='12'></MDBCol>
                                <MDBCol md='6' xs='12'>
                                    <MDBRow className='mb-4'><label className='signInLabel'>Sign In</label></MDBRow>
                                    <MDBRow>
                                        <form className='login-form' onSubmit={onSubmit}>
                                            <MDBRow><label className='inputLabel'>Email address</label></MDBRow>
                                            <input className='form-control mb-4' 
                                                            type="email" 
                                                            onChange={event => setEmail(event.target.value)} 
                                                            id='loginEmail' 
                                                            required></input>
                                            <MDBRow><label className='inputLabel'>Password</label></MDBRow>
                                            
                                            <input className='form-control mb-2' 
                                                    type="password" 
                                                    onChange={event => setPassword(event.target.value)} 
                                                    id='loginPassword' 
                                                    required>

                                            </input>
                                            <MDBRow className='mb-4'>
                                                <a href='/forgot-password'>
                                                    Forgot Password?
                                                </a>
                                            </MDBRow>
                                            <MDBRow className='mb-4 agree_terms' >
                                                <MDBTypography>
                                                    I agree to the
                                                    <a href={termsPDF} target="_blank"> 3M Terms of Use</a>, 
                                                    <a href='https://www.3m.com/3M/en_US/company-us/privacy-policy/' target="_blank"> Privacy and Cookie Policy</a>, and
                                                    <a href='https://www.3m.com/3M/en_US/company-us/legal-information/' target="_blank"> Legal Information</a>
                                                </MDBTypography>
                                            </MDBRow>
                                            <MDBRow center>
                                                <MDBBtn className='mb-3 signinButton' type='submit' block>
                                                    Sign In
                                                </MDBBtn>
                                            </MDBRow>
                                            
                                            <MDBTypography className='orText'>
                                                or
                                            </MDBTypography>
                                            <MDBRow center>
                                                <MDBBtn className='mb-4 requestButton' href={process.env.REACT_APP_SIGNUP_URL} target="_blank" block>
                                                    Request Account
                                                </MDBBtn>
                                            </MDBRow>
                                            
                                            
                                       
                                            {
                                                errorMessage !== '' ?
                                                    <MDBTypography note noteColor='danger'>
                                                        <strong>Error:</strong> {errorMessage}
                                                    </MDBTypography> : ''
                                            }
                                        </form>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol md='3' xs='12'></MDBCol>
                            </MDBRow>
                        </MDBContainer> :
                        <ChangePassword 
                            isFirstLogin={isFirstLogin} 
                            user={user} 
                            userAttr={userAttr} />
            }
        </>
    );
}