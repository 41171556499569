import React from "react";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";

import "./SelectedProduct.css";

export default function SelectedProduct(props) {
  return (
    <MDBContainer className="selected-product-container">
      <MDBTypography className="text-center component-heading" tag="h4">
        Selected Product
      </MDBTypography>
      <div className="square rounded-6 border border-dark bg-white bg-white">
        <MDBRow className="bg ms-1 mt-2">
          <MDBIcon
            role="button"
            fas
            icon="times"
            size="2x"
            onClick={() => props.isProductSelected(false)}
          />
        </MDBRow>
        <MDBRow className="mb-0">
          <MDBTypography className="component-heading" tag="h5">
            {props.productData?.productInfo?.ProductName}
          </MDBTypography>
        </MDBRow>

        <MDBRow className="product-image">
          <img
            src={props.productData?.productInfo?.ProductImage}
            className="img-fluid"
            alt="..."
          />
        </MDBRow>
        <MDBRow className="p-2 product-details square border-top border-black ">
          <MDBTypography className="text-center" tag="strong">
            Lots: {props.productData?.lotBInfo?.SK.split("#")[1]}{" "}
            {props.productData?.lotAInfo?.SK.split("#")[1]}
          </MDBTypography>
        </MDBRow>
      </div>

      {props.isProductExpired ? (
        <MDBRow className="mb-0">
          <MDBTypography note className="mt-2" noteColor="danger">
            Lot codes are expired. Product specifications do not apply to expired products, and prediction accuracy may be reduced.
          </MDBTypography>
        </MDBRow>
      ) : (
        ""
      )}
      <MDBRow className="mt-4">
        <MDBCol className="ps-3 pe-1 mx-auto">
          <MDBBtn
            className="pr"
            href={props.productData?.productInfo?.SDSLink}
            target="_blank"
            block
          >
            Safety Data Sheet
          </MDBBtn>
        </MDBCol>
        <MDBCol className="pe-3 ps-2 mx-auto">
          <MDBBtn
            href={props.productData?.productInfo?.TDSLink}
            target="_blank"
            block
          >
            Dispensing Guide
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
