import React, { useState, useEffect } from "react";

import {
  MDBContainer,
  MDBTypography,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { CheckPermission } from "../CheckPermission";
import * as constants from "../../services/constants";
import "./MachineDetails.css";

export default function MachineDetails(props) {
  const [showEditModal, setShowEditModal] = useState(false);
  useEffect(() => {
  }, [props]);

  let dispenserValue =
    props.machineDetails.MachineInfo.Dispenser === "Other"
      ? props.machineDetails.MachineInfo.OtherDispenser
      : props.machineDetails.MachineInfo.Dispenser;
  let staticMixerValue =
    props.machineDetails.MachineInfo.StaticMixer === "Other"
      ? props.machineDetails.MachineInfo.OtherStaticMixer
      : props.machineDetails.MachineInfo.StaticMixer;
  let adapterValue =
    props.machineDetails.MachineInfo.Adapter === "Other"
      ? props.machineDetails.MachineInfo.OtherAdapter
      : props.machineDetails.MachineInfo.Adapter;
  let valveValue =
    props.machineDetails.MachineInfo.Valve === "Other"
      ? props.machineDetails.MachineInfo.OtherValve
      : props.machineDetails.MachineInfo.Valve;
  let tipValue =
    props.machineDetails.MachineInfo.Tip === "Other"
      ? props.machineDetails.MachineInfo.OtherTip
      : props.machineDetails.MachineInfo.Tip;

  const editMachineSetup = () => {
    setShowEditModal(true);
  };
  const handleEditOk = () => {
    props.toggleIsEdit();
  };
  const cancelEditMachineSetup = () => {
    setShowEditModal(false);
  };
  const editCautionModal = () => {
    if (
      window.confirm(
        "Caution:\n\nAltering components will impact the validity of calibrations. You can edit labels here, but if you are physically changing your Machine Setup, please create a new Machine Setup or re-run all calibrations."
      )
    )
      return;
  };
  let cs_label = (
    <label>
      cross sectional area (mm<sup>2</sup> and mm/s)
    </label>
  );

  return (
    <CheckPermission permission={constants.scopes.canViewMachineDetails}>
      <MDBContainer className="machine-details-container mt-1">
        <MDBTypography className="text-center component-heading" tag="h4">
          Machine Setup Units
        </MDBTypography>

        {props.isLoading ? (
          <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white text-center">
            <MDBSpinner className="calculating-spinner">
              <span className="visually-hidden">Loading...</span>
            </MDBSpinner>
          </MDBContainer>
        ) : (
          <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white">
            <MDBRow>
              <MDBCol className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Unit of Temperature:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">
                  {props.machineDetails.UnitPreferences.Temperature.toLowerCase() ===
                  constants.temperatureUnits.celsius.unit
                    ? constants.temperatureUnits.celsius.name
                    : constants.temperatureUnits.fahrenheit.name}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Unit of Flow Rate:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">
                  {props.machineDetails.UnitPreferences.Weight.toLowerCase() ===
                  constants.weightUnits.gram.unit
                    ? constants.weightUnits.gram.name
                    : props.machineDetails.UnitPreferences.Weight.toLowerCase() ===
                      constants.weightUnits.crossSection.unit
                    ? cs_label
                    : constants.weightUnits.milliliter.name}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Unit of Pressure:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">
                  {props.machineDetails.UnitPreferences.Pressure.toLowerCase() ===
                  constants.pressureUnits.psi.unit
                    ? constants.pressureUnits.psi.name
                    : constants.pressureUnits.kpa.name}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}

        <MDBTypography className="text-center component-heading mt-4" tag="h4">
          Machine Setup Details
        </MDBTypography>
        {props.isLoading ? (
          <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white text-center">
            <MDBSpinner className="calculating-spinner">
              <span className="visually-hidden">Loading...</span>
            </MDBSpinner>
          </MDBContainer>
        ) : (
          <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white">
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Name:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1 text-break">
                <MDBTypography tag="strong">
                  {props.machineDetails.MachineInfo.MachineName}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Dispenser:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">{dispenserValue}</MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Static Mixer:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">{staticMixerValue}</MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Adapter:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">{adapterValue}</MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Valve:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">{valveValue}</MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Tip:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">{tipValue}</MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Min Pressure:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">
                  {props.machineDetails.MachineInfo.MinPressure}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Max Pressure:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">
                  {props.machineDetails.MachineInfo.MaxPressure}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="4" className="d-flex justify-content-end px-1">
                <MDBTypography tag="strong">Notes:</MDBTypography>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start px-1">
                <MDBTypography tag="strong">
                  {props.machineDetails.MachineInfo.Notes}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}

        <MDBBtn
          className="mt-3"
          onClick={() => {
            // props.toggleIsEdit();
            editMachineSetup();
          }}
          block
        >
          Edit Machine Setup Details
        </MDBBtn>
        
        <MDBModal show={showEditModal} staticBackdrop> 
          <MDBModalDialog>
            <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
              <MDBModalHeader>
                <b>Caution</b>
              </MDBModalHeader>
              <MDBModalBody>
                Altering components will impact the validity of calibrations.
                You can edit labels here, but if you are physically changing
                your Machine Setup, please create a new Machine Setup or re-run
                all calibrations.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="cancel-button"
                  size="sm"
                  onClick={cancelEditMachineSetup}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn
                  className="continue-button"
                  color="primary"
                  size="sm"
                  onClick={handleEditOk}
                >
                  Continue
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    </CheckPermission>
  );
}
