import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import AccountService from "../../services/account";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [stage, setStage] = useState(1);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccessModal, setResetSuccessModal] = useState(false);
  const navigate = useNavigate();

  const handleConfirmSuccessful = () => {
    navigate("/machine-setup");
  };

  const sendCode = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    AccountService.getUser(email).forgotPassword({
      onSuccess: (data) => {
        // console.log("onSuccess:", data);
        setIsLoading(false);
      },
      onFailure: (err) => {
        setErrorMessage(err.message);
        setIsLoading(false);
      },
      inputVerificationCode: (_) => {
        setIsLoading(false);
        setStage(2);
      },
    });
  };
  let list = (
    <ul>
      <li>12-character minimum length</li>
      <li>Contains at least 1 number</li>
      <li>
        {
          "Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & /  , > < ' : ; | _ ~ ` + = )"
        }
      </li>
      <li>Contains at least 1 lowercase letter</li>
      <li>Contains at least 1 uppercase letter</li>
    </ul>
  );
  const resetPassword = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (password !== confirmPassword) {
      console.error("Passwords are not the same");
      return;
    }

    AccountService.getUser(email).confirmPassword(code, password, {
      onSuccess: (_) => {
        setCode("");
        setPassword("");
        setConfirmPassword("");
        // setSuccessMessage(
        //   "Your password has been reset successfully! Please login again."
        // );
        setIsLoading(false);
        setResetSuccessModal(true);
      },
      onFailure: (err) => {
        setErrorMessage(err.message);
        setIsLoading(false);
      },
    });
  };

  return (
    <MDBContainer className="mt-5">
      <MDBTypography className="text-center component-heading" tag="h4">
        {stage === 1 ? "Forgot Your Password?" : "Change Password"}
      </MDBTypography>
      <MDBRow className="mt-2">
        <MDBCol lg="4" md="3" xs="12"></MDBCol>
        <MDBCol>
          {stage === 1 && (
            <>
              <form
                id="forgot-password-form"
                onSubmit={sendCode}
                className="square rounded-6 border border-dark px-3 py-2 bg-white"
              >
                <label className="d-flex justify-content-start">
                  We will send a One Time Password (OTP) on your registered
                  email to reset your password.
                </label>
                <MDBInput
                  className="mb-4 mt-3"
                  name="userName"
                  type="email"
                  label="Email Address*"
                  onChange={(event) => setEmail(event.target.value)}
                  id="loginEmail"
                  disabled={isLoading}
                  required
                />
              </form>
              <MDBBtn
                form="forgot-password-form"
                type="submit"
                className="mt-3"
                block
              >
                Submit
              </MDBBtn>
            </>
          )}

          {stage === 2 && (
            <>
              <form
                id="change-password-form"
                onSubmit={resetPassword}
                className="square rounded-6 border border-dark px-3 py-2 bg-white"
              >
                <label className="d-flex justify-content-start">
                  Please check your email for the OTP.
                </label>
                <MDBInput
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  className="mb-3 mt-2"
                  type="text"
                  disabled={isLoading}
                  required
                  label="One Time Password (OTP)*"
                />

                <MDBInput
                  className="mb-3"
                  type="password"
                  id="new-password"
                  disabled={isLoading}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  name="NewPassword"
                  required
                  label="New Password*"
                />

                <MDBInput
                  className="mb-2"
                  type="password"
                  id="confirm-new-password"
                  disabled={isLoading}
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  name="ConfirmNewPassword"
                  required
                  label="Confirm New Password*"
                />
              </form>
              <MDBBtn
                form="change-password-form"
                type="submit"
                className="mt-3"
                block
              >
                Change Password
              </MDBBtn>
            </>
          )}

          {errorMessage !== "" ? (
            <MDBTypography note noteColor="danger" className="mt-2">
              <strong>Error:</strong> {errorMessage}
            </MDBTypography>
          ) : (
            ""
          )}
          {successMessage !== "" ? (
            <MDBTypography note noteColor="success" className="mt-2">
              {successMessage}
            </MDBTypography>
          ) : (
            ""
          )}
          {isLoading ? (
            <MDBContainer className="text-center mt-3">
              <MDBSpinner className="calculating-spinner">
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            </MDBContainer>
          ) : (
            ""
          )}
        </MDBCol>
        <MDBCol lg="4" md="3" xs="12">
          <MDBTypography style={{ fontSize: 12 }} tag="h6">
            Password policy
          </MDBTypography>
          {list}
        </MDBCol>
      </MDBRow>
      <MDBModal show={resetSuccessModal} staticBackdrop>
          <MDBModalDialog>
            <MDBModalContent className="square rounded-6 border border-dark p-3 bg-white">
              <MDBModalHeader><b>Message</b></MDBModalHeader>
              <MDBModalBody>Password reset successful!</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn className="okay-button" size="sm" onClick={handleConfirmSuccessful}>
                  Okay
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
    </MDBContainer>
  );
}
