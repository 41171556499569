import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBModal
} from "mdb-react-ui-kit"
import MachineDetails from "../../components/MachineDetails"
import MachineCalibration from "../../components/MachineCalibration"
import AddMachine from "../../components/AddMachine";
import MachineService from "../../services/machine"
import { CheckPermission } from "../../components/CheckPermission"
import * as constants from '../../services/constants'

export default function MachineSetup() {
    const { userData } = useSelector(state => state)
    const location = useLocation()
    const navigate = useNavigate()
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const toggleIsEdit = () => setIsEdit(!isEdit)
    const [machineDetails, setMachineDetails] = useState(
        {
            PK: '',
            SK: '',
            MachineInfo: {
                MachineName: '',
                Dispenser: '',
                StaticMixer: '',
                Valve: '',
                Tip: '',
                TipDiscription: '',
                MinPressure: 1,
                MaxPressure: 1,
                Notes: ''
            },
            UnitPreferences: {
                Pressure: '',
                Temperature: '',
                Weight: ''
            }
        })

    const machineId = location?.state?.machineId
    useEffect(() => {
        setIsLoading(true)
        if (!userData || !userData.isLoggedIn) {
            navigate('/')
            return
        }

        if (machineId) {
            MachineService.getMachineDetails(machineId)
                .then(items => {
                    setMachineDetails(items)
                    setIsLoading(false)
                })
        }
    }, [userData, navigate, machineId])

    return (
        <>
            {
                userData.isLoggedIn && !isLoading ?
                    <CheckPermission permission={constants.scopes.canViewMachineDetails} isComponent={true}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol lg='4' className='mt-3'>
                                    {
                                        !isEdit ?
                                            <MachineDetails machineDetails={machineDetails} isLoading={isLoading}
                                                toggleIsEdit={toggleIsEdit}></MachineDetails> :
                                            <AddMachine machineDetails={machineDetails} setMachineDetails={setMachineDetails}
                                                toggleIsEdit={toggleIsEdit} isLoading={isLoading} isEdit={isEdit}></AddMachine>
                                    }
                                </MDBCol>
                                <MDBCol lg='8' className='mt-3'>
                                    <MachineCalibration machineDetails={machineDetails}
                                        machineId={machineId} isLoading={isLoading}>
                                    </MachineCalibration>
                                </MDBCol>
                            </MDBRow>
                            <MDBModal tabIndex='-1' show={isEdit} setShow={setIsEdit}>
                            </MDBModal>
                        </MDBContainer>
                    </CheckPermission> :
                    ''
            }
        </>
    )
}