import React, { useState, useEffect } from "react";
import { MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";

export default function SelectOrTextInput(props) {
  const [showTextBox, setShowTextBox] = useState(false);

  useEffect(() => {
    if (props.value === "Other") {
      setShowTextBox(true);
    }
  }, [props.value]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === props.name && value === "Other") {
      setShowTextBox(true);
    }
    props.onChange(e);
  };

  const removeTextBox = () => {
    setShowTextBox(false);
    props.resetSelectInput(props.name);
  };

  return (
    <>
      <label>{props.text}</label>
      <select
        disabled={showTextBox || props.disabled}
        className="form-control mb-2"
        onChange={handleChange}
        value={props.value}
        name={props.name}
        id={`select${props.name}`}
        required
      >
        <option disabled key="" value="">
          Select {props.text}
        </option>
        {props.itemList.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
      </select>
      {showTextBox && (
        <>
          <MDBInputGroup className="mb-2">
            <input
              className="form-control mb-2"
              type="input"
              name={`Other${props.name}`}
              placeholder="Please describe"
              value={props.other}
              onChange={handleChange}
              required={showTextBox}
              disabled={props.disabled}
            />
            <MDBBtn
              onClick={removeTextBox}
              color="none"
              className="btn-close"
              disabled={props.disabled}
            />
          </MDBInputGroup>
        </>
      )}
    </>
  );
}
