import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBTypography,
    MDBBtn,
    MDBSpinner
} from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import ProductService from "../../services/product";


export default function UserProfile() {
    const navigate = useNavigate()
    const [isValid, setIsValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const { userData } = useSelector(state => state)
    const [CompanyName, setCompanyName] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    // gets initial data
    useEffect(() => {
        setIsLoading(true)
        ProductService.getCompany()
            .then(items => {
                setCompanyName(items)
                setIsLoading(false)
            })
            .catch(error => {
                if (error.message === 'Unauthorized') {
                    navigate('/unauthorized')
                }
            })
    }, [navigate])

    return (
        <MDBContainer className='mt-5'>
            <MDBTypography className="text-center component-heading" tag='h4'>
                User Details
            </MDBTypography>
            <MDBRow>
                <MDBCol lg='2' md='2' sm='2'></MDBCol>
                {
                    userData.isLoggedIn && !isLoading ?
                        <MDBCol>
                            <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white">
                                <MDBRow>
                                    <MDBCol lg='2' sm='1' md='1' xs='1' className="d-flex px-1 mx-1">
                                        <MDBTypography tag='strong'>
                                            Email:
                                        </MDBTypography>
                                    </MDBCol>
                                    <MDBCol className="d-flex px-1 mx-1" md='11' sm='11' xs='11'>
                                        {userData.email}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-2">
                                    <MDBCol lg='2' sm='1' md='1' xs='1' className="d-flex px-1 mx-1">
                                        <MDBTypography tag='strong'>
                                            Role:
                                        </MDBTypography>
                                    </MDBCol>
                                    <MDBCol className="d-flex px-1 mx-1" md='11' sm='11' xs='11'>
                                        {userData.role}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-2">
                                    <MDBCol lg='2' sm='1' md='1' xs='1' className="d-flex px-1 mx-1">
                                        <MDBTypography tag='strong'>
                                            Company:
                                        </MDBTypography>
                                    </MDBCol>
                                    <MDBCol className="d-flex px-1 mx-1" md='11' sm='11' xs='11'>
                                        {CompanyName}
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                            <MDBBtn type='submit' className="mt-3"
                                onClick={() => navigate('/change-password')} block>
                                Change Password
                            </MDBBtn>
                        </MDBCol> :
                        <MDBCol>
                            <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white text-center">
                                <MDBSpinner className="calculating-spinner">
                                    <span className='visually-hidden'>Loading...</span>
                                </MDBSpinner>
                            </MDBContainer>
                        </MDBCol>

                }
                <MDBCol lg='2' md='2' sm='2'></MDBCol>
            </MDBRow>
        </MDBContainer >
    )
}