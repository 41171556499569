import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as constants from '../../services/constants'
import Unauthorized from '../../views/Unauthorized/Unauthorized'

const CheckPermissionComponent = props => {
    const couldShow = props.userPermissions.includes(props.permission)
    return couldShow ? props.children :
        props.isComponent ? <Unauthorized /> : ''
}

CheckPermissionComponent.propTypes = {
    permission: PropTypes.string.isRequired,
    userPermissions: PropTypes.array.isRequired
}

const mapStateToProps = state => {
    return ({
        userPermissions: constants.permissions[state.userData.role] ?
            constants.permissions[state.userData.role] : []
    })
}

export const CheckPermission = connect(mapStateToProps)(CheckPermissionComponent)