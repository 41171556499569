import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBTypography,
  MDBListGroup,
  MDBListGroupItem,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBSpinner,
  MDBRow,
} from "mdb-react-ui-kit";
import MachineService from "../../services/machine";
import { CheckPermission } from "../CheckPermission";
import * as constants from "../../services/constants";
import "./ListMachines.css";
import faqPDF from "../../FAQs.pdf";
import instructionPDF from "../../User_Instructions.pdf";

export default function ListMachines() {
  const [machineList, setMachineList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    MachineService.getMachineList()
      .then((items) => {
        setMachineList(items);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.message === "Unauthorized") {
          navigate("/unauthorized");
        }
      });
  }, [navigate]);

  // Navigate to machine details page
  const handleClick = (machineId) => {
    navigate("/machine-details", {
      state: {
        machineId: machineId,
      },
    });
  };

  return (
    <CheckPermission permission={constants.scopes.canViewMachineSetup}>
      <MDBContainer className="list-machine-container">
        <MDBTypography className="text-center component-heading" tag="h4">
          View Machine Setup
        </MDBTypography>
        <MDBContainer className="list-machine-container square rounded-6 border border-dark bg-white">
          {isLoading ? (
            <MDBCol className="text-center my-4">
              <MDBSpinner className="calculating-spinner">
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            </MDBCol>
          ) : (
            <MDBListGroup flush>
              {machineList.map((item) => (
                <MDBListGroupItem
                  className="border-bottom border-dark machine-list-item"
                  onClick={() => handleClick(item.PK, item.SK)}
                  key={item.PK}
                >
                  {item.MachineInfo.MachineName}
                </MDBListGroupItem>
              ))}
            </MDBListGroup>
          )}
        </MDBContainer>

        <MDBBtn
          className="me-2 my-4"
          color="light"
          size="lg"
          href={instructionPDF}
          rel="noreferrer"
          target="_blank"
        >
          <MDBIcon fas icon="info-circle mx-2" size="lg" />
          User Instructions
        </MDBBtn>
        <MDBBtn
          className="my-4 me-2"
          color="light"
          size="lg"
          href={faqPDF}
          rel="noreferrer"
          target="_blank"
        >
          <MDBIcon className="me-2" fas icon="question-circle" size="lg" />
          FAQs
        </MDBBtn>
      </MDBContainer>
    </CheckPermission>
  );
}
