import AccountService from "./account"
import store from '../store'
import { reset } from "./userDataSlice"

const PressureCalculatorService = (function () {

  // posts the user data from pressure calculator to the api endpoint
  // inputData already has user entered into, machine, product, and lot info
  const calculatePressure = async function (input_data) {
    const session = await getCurrentSession()

    const data = await fetch(`${getAPIServerURL()}/api/pressurecalc/calc`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(input_data)
    });
    
    let response = await data.json();
    return response;
  }

  const getCurrentSession = async () => {
    var session = await AccountService.getSession()
    .then(session => {
      return session
    }).catch(_ => {
      AccountService.logout()
      store.dispatch(reset())
    })
    return session
  }

  // gets API server URL
  const getAPIServerURL = () => !process.env.REACT_APP_API_URL ? '' : process.env.REACT_APP_API_URL;

  return {
    calculatePressure: calculatePressure
  }

})()

export default PressureCalculatorService
