import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  role: '',
  email: '',
  tenantId: '',
  isLoggedIn: false
}

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    save: (state, param) => {
      const { payload } = param
      state.role = payload.role
      state.email = payload.email
      state.tenantId = payload.tenantId
      state.isLoggedIn = payload.isLoggedIn
    },
    reset: () => initialState,
    default: state => state
  },
})

export const { save, reset } = userDataSlice.actions
export default userDataSlice.reducer