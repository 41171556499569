import AccountService from "./account"
import store from '../store'
import { reset } from "./userDataSlice"

const CalibrationService = (function () {
  // gets the list of calibrations from the server
  const getCalibrations = async function (machineId) {
    const session = await getCurrentSession()
    const data = await fetch(`${getAPIServerURL()}/api/calibration/machine/${encodeURIComponent(machineId)}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + session.accessToken.jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });
    return await data.json();
  }

  // save calibration data
  const saveCalibrationData = async function (machineId, item) {
    const session = await getCurrentSession()
    const data = await fetch(`${getAPIServerURL()}/api/calibration/machine/${encodeURIComponent(machineId)}`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    });
    return await data.json();
  }

  // call the verify calibration lambda
  const verifyCalibration = async function (machineId, item) {
    const session = await getCurrentSession()
    const data = await fetch(`${getAPIServerURL()}/api/calibration/machine/${encodeURIComponent(machineId)}/verify`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    });
    return await data.json();
  }

  const deleteMachineCalibration = async function (machineId, calibrationId) {
    const session = await getCurrentSession()
    const data = await fetch(`${getAPIServerURL()}/api/calibration/machine/${encodeURIComponent(machineId)}/${encodeURIComponent(calibrationId)}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + session.idToken.jwtToken,
        'Content-Type': 'application/json'
      },
    });
    let response = await data.json();
    return response;
  }

  const getCurrentSession = async () => {
    const session = await AccountService.getSession()
      .then(session => {
        return session
      }).catch(_ => {
        AccountService.logout()
        store.dispatch(reset())
      })
    return session
  }

  // gets API server URL
  const getAPIServerURL = () => !process.env.REACT_APP_API_URL ? '' : process.env.REACT_APP_API_URL;

  return {
    getCalibrations: getCalibrations,
    saveCalibrationData: saveCalibrationData,
    verifyCalibration: verifyCalibration,
    deleteMachineCalibration: deleteMachineCalibration
  }

})()

export default CalibrationService
