import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import ListMachines from "../../components/ListMachines";
import AddMachine from "../../components/AddMachine";
import {
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdb-react-ui-kit";
import * as constants from '../../services/constants'
import { useSelector } from "react-redux";
import { CheckPermission } from "../../components/CheckPermission";

export default function MachineSetup() {
    const navigate = useNavigate()
    const { userData } = useSelector(state => state)

    useEffect(() => {
        if (!userData || !userData.isLoggedIn) {
            navigate('/')
        }
        return () => {};
    }, [navigate, userData])
    return (
        <>
            {
                userData.isLoggedIn ?
                    <CheckPermission permission={constants.scopes.canViewMachineSetup} isComponent={true}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol className="mt-3 mb-2" lg="1" md="12"></MDBCol>
                                <MDBCol className="mt-3 mb-2" lg="5" md="6">
                                    <ListMachines></ListMachines>
                                </MDBCol>
                                <MDBCol className="mt-3 mb-2" lg="5" md="6">
                                    <AddMachine></AddMachine>
                                </MDBCol>
                                <MDBCol className="mt-3 mb-2" lg="1" md="12"></MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </CheckPermission> :
                    ''
            }
        </>
    )
}