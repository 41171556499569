import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBContainer,
  MDBBtn,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBSpinner,
  MDBCollapse,
} from "mdb-react-ui-kit";

import CalibrationService from "../../services/calibration";
import ProductService from "../../services/product";
import AddCalibration from "../../components/AddCalibration";
import CalibrationDetails from "../CalibrationDetails";
import faqPDF from "../../FAQs.pdf";
import "./MachineCalibration.css";

export default function MachineCalibration(props) {
  const [calibrationList, setCalibrationList] = useState([]);
  const [showDetails, setShowDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCalibration, setSelectedCalibration] = useState({});
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();

  const toggleShowDetails = (item) => {
    if (item.DataPoints[0].tp > item.DataPoints[item.DataPoints.length - 1].tp) {
      item["DataPoints"] = item["DataPoints"].reverse();
    }
    setSelectedCalibration(item);
    let showDetailsArray = [];
    calibrationList.forEach((calibration) => {
      showDetailsArray.push({
        [calibration.PK]: false,
      });
    });
    showDetailsArray[item.PK] = !showDetails[item.PK];
    setShowDetails(showDetailsArray);
  };

  // get product name by product id
  const getProductName = (productGuid) => {
    let product = productList.find((p) => {
      return p.PK === productGuid;
    });

    return product ? product.ProductName : "";
  };

  // a list of calibration instruction
  let list = (
    <ol type="1" style={{ fontSize: 14, color: "black" }}>
      <li>
        Prepare 8 cups by recording their empty mass and labeling each
        container.
      </li>
      <li>
        Click "Add Calibration Test" in the upper right to begin a new
        calibration.
      </li>
      <li>
        Set pressure gauge as close to the first target pressure as possible.
      </li>
      <li>Enter the actual pressure from your gauge.</li>
      <li>Dispense the adhesive into one of the pre-weighed cups.</li>
      <li>
        Repeat steps 3-5 for each additional target pressure before moving to
        step 7.
      </li>
      <li>
        Weigh the cups, subtract starting weights, and enter net mass of each
        cup.
      </li>
      <li>Click “Save and Verify Calibration”.</li>
      <li>Calibration is complete when the status indicator turns green.</li>
      <li>
        Click "Pressure Calculator" tab at the top to use calibrated machine
        setups.
      </li>
    </ol>
  );

  useEffect(() => {
    setIsLoading(true);
    if (!props.isLoading) {
      Promise.all([
        ProductService.getAllProducts(),
        CalibrationService.getCalibrations(props.machineId),
      ])
        .then(([products, calibrations]) => {
          if (products) {
            setProductList(products);
          }

          if (calibrations) {
            function compareObjects(object1, object2, firstkey) {
              const obj1 = object1[firstkey].toUpperCase();
              const obj2 = object2[firstkey].toUpperCase();
              if (obj1 < obj2) {
                return 1;
              }
              if (obj1 > obj2) {
                return -1;
              }
              return 0;
            }

            calibrations.sort((a, b) => {
              return compareObjects(a, b, "Date");
            });

            setCalibrationList(calibrations);

            let showDetailsArray = [];
            calibrations.forEach((calibration) => {
              showDetailsArray.push({
                [calibration.PK]: false,
              });
            });
            setShowDetails(showDetailsArray);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.message === "Unauthorized") {
            navigate("/unauthorized");
          }
        })
        .finally(() => setIsLoading(false));
      return () => setIsLoading(false);
    }
  }, [props, navigate]);

  const removeCalibration = (id) => {
    let tempCalibrationList = calibrationList.filter((item) => item.PK !== id);
    setCalibrationList(tempCalibrationList);
    setSelectedCalibration({});
    let showDetailsArray = [];
    tempCalibrationList.forEach((calibration) => {
      showDetailsArray.push({
        [calibration.PK]: false,
      });
    });
    setShowDetails(showDetailsArray);
  };

  const addNewCalibration = (calib) => {
    // add to calib state list
    setCalibrationList((prevState) => [...prevState, calib]);
    // toggle based on this calibration
    toggleShowDetails(calib);
  };

  return (
    <MDBContainer className="pe-0">
      <MDBRow>
        <MDBCol size="3"></MDBCol>
        <MDBCol className="d-flex justify-content-end">
          <MDBTypography className="component-heading" tag="h4">
            Machine Setup Calibration
          </MDBTypography>
        </MDBCol>
        {!isLoading ? (
          <MDBCol className="add-calibration-link d-flex justify-content-end mt-2 me-1">
            <AddCalibration
              machineDetails={props.machineDetails}
              machineId={props.machineId}
              productList={productList}
              setCalibrationList={setCalibrationList}
              unitPreferences={props.machineDetails?.UnitPreferences}
              addCalib={addNewCalibration}
            ></AddCalibration>
          </MDBCol>
        ) : (
          <MDBCol className="add-calibration-link d-flex justify-content-end mt-2 me-1"></MDBCol>
        )}
      </MDBRow>
      {isLoading ? (
        <MDBContainer className="square rounded-6 border border-dark px-3 py-2 bg-white text-center">
          <MDBSpinner className="calculating-spinner">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </MDBContainer>
      ) : (
        <MDBTable small className="calibration-table table mt-1 table-hover">
          <MDBTableHead>
            <tr>
              <th colSpan="1" scope="col" className="py-1 pe-0 ps-2"></th>
              <th colSpan="2" scope="col" className="py-1 pe-0">
                Product
              </th>
              <th colSpan="2" scope="col" className="py-1 px-0">
                Lot codes
              </th>
              <th colSpan="2" scope="col" className="py-1 px-0">
                Date
              </th>
              <th colSpan="2" scope="col" className="py-1 px-0">
                Temperature (<sup>o</sup>
                {props.machineDetails?.UnitPreferences?.Temperature.toUpperCase()}
                )
              </th>
              <th colSpan="2" scope="col" className="py-1 px-0">
                Time for Dispensing (s)
              </th>
              <th colSpan="1" scope="col" className="py-1 ps-0 pe-2 col-1">
                Status
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody className="bg-white">
            {calibrationList.map((item) => (
              <React.Fragment key={item.PK}>
                <tr onClick={() => toggleShowDetails(item)}>
                  <td colSpan="1" className="py-1 pe-0 ps-2 collapse-toggle">
                    <MDBIcon
                      fas
                      icon={
                        !showDetails[item.PK] ? "angle-right" : "angle-down"
                      }
                      size="lg"
                      onClick={() => toggleShowDetails(item)}
                    />
                  </td>
                  <td colSpan="2" className="py-1 pe-0">
                    {getProductName(item.ProductGuid)}
                  </td>
                  <td colSpan="2" className="py-1 px-0">
                    {item.LotB} {item.LotA}
                  </td>
                  <td colSpan="2" className="py-1 px-0">
                    {item.Date.split("-")[1]}/{item.Date.split("-")[2]}/
                    {item.Date.split("-")[0]}
                  </td>
                  <td colSpan="2" className="py-1 px-0 temp-data">
                    {item.AmbientTemperature}
                  </td>
                  <td colSpan="2" className="py-1 px-0 dispense-time-data">
                    {item.TimeDispensed}
                  </td>
                  {item.Status ? (
                    <td colSpan="1" className="py-1">
                      <div className="green-dot"></div>
                    </td>
                  ) : (
                    <td colSpan="1" className="py-1">
                      <div className="red-dot"></div>
                    </td>
                  )}
                </tr>
                {showDetails[item.PK] ? (
                  <tr>
                    <td colSpan="12">
                      <MDBCollapse
                        show={showDetails[item.PK]}
                        style={{ height: "auto" }}
                      >
                        <CalibrationDetails
                          calibrationData={selectedCalibration}
                          currentCalibration={selectedCalibration}
                          machineDetails={props.machineDetails}
                          onDelete={removeCalibration}
                        ></CalibrationDetails>
                      </MDBCollapse>
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
          </MDBTableBody>
        </MDBTable>
      )}
      <MDBTypography
        style={{
          fontSize: 15,
          textDecorationLine: "underline",
          color: "black",
        }}
        tag="h6"
      >
        Calibration Instructions
      </MDBTypography>
      <p style={{ fontSize: 14, color: "black" }}>
        The 3M™ Scotch-Weld™ Digital Dispensing Assistant will guide the user
        through a designated calibration process. Calibration is achieved by
        weighing several shots of adhesive from your dispenser. Simply set your
        dispenser to the given values, dispense into cups, and record the net
        masses of dispensed adhesive. The verification process will confirm a
        completed calibration.
      </p>
      {list}
      <MDBBtn
        className="my-2 me-2"
        color="light"
        size="lg"
        href={faqPDF}
        rel="noreferrer"
        target="_blank"
      >
        <MDBIcon className="me-2" fas icon="question-circle" size="lg" />
        Additional Guidance in the FAQs
      </MDBBtn>
      {/* <a href={faqPDF} rel="noreferrer" target="_blank">
        Additional Guidance in the FAQs
      </a> */}
    </MDBContainer>
  );
}
